import React from 'react'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TablePagination from '@material-ui/core/TablePagination'
import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import FieldFactory from '~components/atom/FieldFactory/FieldFactory'
import { Pie, Line } from 'react-chartjs-2'
import Colors from '~shared/assets/styles/colors'

const chart = (classes, item) => {
  return (
    <React.Fragment>
      <div style={{ display: 'inline-flex', width: '100%' }}>
        <div style={{ width: '30%' }}>
          <Typography variant="subtitle1">Storage:</Typography>
          <Typography>30GB of 40GB used</Typography>
        </div>
        <div style={{ width: '70%' }}>
          <Pie data={item.chartData} height={250} />
        </div>
      </div>
    </React.Fragment>
  )
}

class Stats extends React.Component {
  rowsPerPage = 10

  defaultDatasetConfig = {
    radius: 1,
    hitRadius: 10,
    pointHoverRadius: 4,
    pointHoverBorderColor: 'transparent',
    backgroundColor: 'transparent',
  }

  options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            stepSize: 25,
            max: 100,
          },
        },
      ],
    },
    tooltips: {
      mode: 'point',
      callbacks: {
        label: function ({ datasetIndex, index }, data) {
          return ' ' + data.datasets[datasetIndex].data[index] + '% ' + data._labels[index]
        },
      },
    },
  }

  constructor(props) {
    super(props)
    this.state = { pageIndex: Math.ceil((props.data || []).length / this.rowsPerPage) - 1 }
  }

  render() {
    let { data, data: { length } = [], diskData = [], memoryData = [] } = this.props
    const { pageIndex } = this.state

    let sliced = {}
    if (data) {
      const startRowIndex = pageIndex * this.rowsPerPage
      const endRowIndex = startRowIndex + this.rowsPerPage
      sliced = {
        data,
        diskData,
        memoryData,
      }
      Object.keys(sliced).forEach(key => {
        sliced[key] = sliced[key].slice(startRowIndex, endRowIndex)
      })

      const correction = sliced.data.length % this.rowsPerPage
      correction !== 0 &&
        (sliced.data.length += this.rowsPerPage - (sliced.data.length % this.rowsPerPage))
    }

    return (
      <React.Fragment>
        {sliced.data && (
          <React.Fragment>
            <div style={{ display: 'inline-flex', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <Line
                  data={{
                    _labels: sliced.data.map(entry => entry && moment(entry._timestamp).toString()),
                    labels: sliced.data.map(() => ''),
                    datasets: [
                      this._getDadasetConfig('Disk space', sliced.diskData, Colors.GrassGreen),
                      this._getDadasetConfig('Memory', sliced.memoryData, Colors.Orange),
                      // this._getDadasetConfig('CPU', sliced.cpuData, Colors.LightBlue),
                    ],
                  }}
                  options={this.options}
                  height={150}
                />
              </div>
            </div>
            {!!length && (
              <TablePagination
                component="div"
                count={length}
                rowsPerPage={this.rowsPerPage}
                rowsPerPageOptions={[this.rowsPerPage]}
                page={pageIndex}
                onChangePage={this._handleChangePage}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  _getDadasetConfig = (label, data, color) => ({
    ...this.defaultDatasetConfig,
    pointHoverBackgroundColor: color,
    borderColor: color,
    label,
    data,
  })

  _handleChangePage = (e, pageIndex) => {
    this.setState({ pageIndex })
  }
}

const stats = (classes, { data, keyFigures = [] }) => {
  const diskData = data && data.map(({ disk = {} }) => Number(disk.usedpct).toFixed(2))
  const memoryData = data && data.map(({ memory = {} }) => Number(memory.usedpct).toFixed(2))
  // const cpuData = data && data.map(({cpu = {}}) => Number(cpu.syspct).toFixed(2))

  return (
    <Stats
      data={data}
      keyFigures={keyFigures}
      diskData={diskData}
      memoryData={memoryData}
      // cpuData={cpuData}
    />
  )
}

class TableWithPagination extends React.Component {
  entriesPerPage = 5

  constructor(props) {
    super(props)

    const { item: { value: { rowData = [] } = {} } = {} } = props
    const slicedRowData = rowData.slice(0, this.entriesPerPage)
    this.state = { pageIndex: 0, rowData: slicedRowData }
  }

  render() {
    const {
      item,
      item: { value: { rowData: fullRowData = [] } = {} },
      history,
    } = this.props
    const { rowData, pageIndex } = this.state

    return (
      <React.Fragment>
        {FieldFactory.getFieldByType({ ...item, history, value: { ...item.value, rowData } })}
        <TablePagination
          component="div"
          count={fullRowData.length}
          rowsPerPage={5}
          rowsPerPageOptions={[5]}
          page={pageIndex}
          onChangePage={this._handleChangePage}
        />
      </React.Fragment>
    )
  }

  _handleChangePage = (e, pageIndex) => {
    const { item: { value: { rowData = [] } = {} } = {} } = this.props
    const slicedRowData = rowData.slice(
      this.entriesPerPage * pageIndex,
      this.entriesPerPage * (pageIndex + 1),
    )
    this.setState({ pageIndex, rowData: slicedRowData })
  }
}

const table = (item, history) => <TableWithPagination item={item} history={history} />

const list = (classes, item) => {
  return (
    <List>
      {item.items.map(({ link, icon, primary, secondary }) => {
        return (
          <ListItem component={'a'} button className={classes.items} href={link} target="blank">
            <Avatar>
              <Icon>{icon}</Icon>
            </Avatar>
            <ListItemText primary={primary} secondary={secondary} />
          </ListItem>
        )
      })}
    </List>
  )
}

const renderSections = (item, classes, history) => {
  switch (item.type) {
    case 'table':
      return table(item, history)
    case 'list':
      return list(classes, item)
    case 'chart':
      return chart(classes, item)
    case 'stats':
      return stats(classes, item)
    default:
      break
  }
}

class AdminDashboard extends React.Component {
  render() {
    const { classes, data = [], history } = this.props

    return (
      <Grid container justify="center" spacing={24}>
        {data.map((item, k) => {
          return (
            <Grid item lg={item.columns} sm={12} md={item.columns > 6 ? item.columns : 6} key={k}>
              <Paper className={classes.paper}>
                <Typography variant="h5" gutterBottom>
                  {item.title}
                </Typography>
                {renderSections(item, classes, history)}
              </Paper>
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
  action: {
    marginTop: theme.spacing.unit * 2,
  },
  items: {
    paddingLeft: theme.spacing.unit,
    cursor: 'pointer',
  },
  logo: {
    marginBottom: 40,
    textAlign: 'center',
  },
})

export default withStyles(styles)(AdminDashboard)
