import React from 'react'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Dropzone from 'react-dropzone'
import withStyles from '@material-ui/core/styles/withStyles'
import { TabContainer } from './TabContainer'
import { styles } from './Styles'
import TagSelection from './TagSelection'
import PageProgress from '~shared/components/PageProgress'
import classNames from 'classnames'

class ListMediaContainer extends React.Component {
  state = {
    selectedOption: null,
    selectedTags: [],
  }

  render() {
    const {
      classes,
      contentType,
      previewFiles,
      removePreviewFile,
      uploadFile,
      uploadAllFiles,
      imageMaxSize,
      handleOnDrop,
      handleAddChipOnUpload,
      visible,
      parsedTags = [],
      onHandleCreate,
    } = this.props

    return (
      <TabContainer>
        <PageProgress visible={visible} className={classes.progress} />
        <div className={classes.wrapper}>
          <Grid
            container
            direction={'row'}
            justify={'center'}
            spacing={24}
            className={classes.wrapperBottom}
          >
            {previewFiles.map((file, k) => {
              return (
                <Grid item key={k}>
                  <Card className={classNames(classes.cardUpload, classes.card)}>
                    <CardMedia
                      src={contentType(file, true)}
                      image={contentType(file, true)}
                      className={classNames(classes.media, classes.preview, classes.previewImage)}
                      title={file.name}
                    />
                    <CardActions className={classes.action}>
                      <Button
                        color="primary"
                        onClick={() => removePreviewFile(file)}
                        className={classes.actionButton}
                      >
                        REMOVE
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => uploadFile(file, k)}
                        className={classes.actionButton}
                      >
                        UPLOAD
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )
            })}
            <Grid item>
              <Dropzone onDrop={handleOnDrop} maxSize={imageMaxSize} className={classes.dropzone}>
                <div className={classes.dropzoneContent}>
                  <Icon className={classes.largeIconUpload}>cloud_upload</Icon>
                  <Typography variant="subtitle1">Drag and drop your files or browse</Typography>
                </div>
              </Dropzone>
            </Grid>
          </Grid>
        </div>
        <Grid item xs={12} className={classNames(classes.center, classes.uploadAllButton)}>
          <Grid container alignItems={'center'} direction={'row'} justify={'center'}>
            <Button
              variant="outlined"
              onClick={uploadAllFiles}
              className={classNames(classes.leftIcon)}
              disabled={!previewFiles.length}
            >
              <Icon className={classes.leftIcon}>cloud_upload</Icon>
              Upload All
            </Button>
            <TagSelection
              parsedTags={parsedTags}
              onHandleAddChip={handleAddChipOnUpload}
              onHandleCreate={onHandleCreate}
              isDisabled={!previewFiles.length}
              isUpload
            />
          </Grid>
        </Grid>
      </TabContainer>
    )
  }

  renderTags() {
    const { previewFiles, tagsOnUpload, handleDeleteChipOnUpload, classes } = this.props
    if (Array.isArray(previewFiles) && previewFiles.length && Array.isArray(tagsOnUpload)) {
      return tagsOnUpload.map((tag, k) => {
        return (
          <Chip
            key={k}
            label={tag}
            onDelete={handleDeleteChipOnUpload.bind(this, k)}
            className={classes.chip}
          />
        )
      })
    }
  }
}

export default withStyles(styles)(ListMediaContainer)
