import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DropDown from '../SegmentationQuery/DropDown'
import { withApi } from '~shared/api/ApiContext'
import { insertBetween } from '~shared/utils'
import CustomJSON from '~components/atom/FieldFactory/CustomJSON'

const styles = () => ({
  root: {},
  select: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 34,
  },
  condition: {
    width: '100%',
    margin: 0,
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.1)',
  },
  and: {
    margin: `4px 0`,
  },
  addButton: {
    marginTop: 5,
  },
})

class AutoTestJson extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {
    const { api } = this.props

    let { request } = api.getAutoTestSegmentationOptions()

    let options
    let optionsModels
    let optionsActions
    try {
      options = await request

      optionsModels = options.models
      optionsActions = options.actionVariants
    } catch (err) {
      return
    }

    this.setState({ optionsModels, optionsActions })
  }

  inputFileRef = React.createRef()

  _getDataByIndex = index => {
    const { value: query } = this.props
    return query[index]
  }

  _updateData = (index, data) => {
    const { value: query } = this.props

    let oldData = this._getDataByIndex(index)

    const newQuery = [...query]
    oldData = { ...oldData, ...data }
    newQuery[index] = oldData

    const { onChange } = this.props

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  /**
   * @description Detect delimiter by header
   * @param {string} firstLine
   * @return {"," | ";" | "\t" | "|"}
   */
  detectDelimiterByHeader(firstLine) {
    const delimiters = {
      ',': 0,
      ';': 0,
      '\t': 0,
      '|': 0,
    }

    for (const delimiter in delimiters) {
      const count = (firstLine.match(new RegExp(`\\${delimiter}`, 'g')) || []).length
      delimiters[delimiter] += count
    }

    let maxDelimiter = ','
    let maxCount = delimiters[',']
    for (const delimiter in delimiters) {
      if (delimiters[delimiter] > maxCount) {
        maxDelimiter = delimiter
        maxCount = delimiters[delimiter]
      }
    }

    return maxDelimiter
  }

  handleCollNameChange = index => e => {
    const { value } = e.target
    this._updateData(index, { collection: value })
  }

  handleActionChange = index => e => {
    const { value } = e.target
    this._updateData(index, { action: value })
  }

  handleValueChange = index => async value => {
    try {
      JSON.parse(value)
    } catch (e) {
      console.log(e)
    }
    this._updateData(index, { value: value })
  }

  handleRemoveData = index => () => {
    const { value: query, onChange } = this.props

    const newQuery = [...query]
    newQuery.splice(index, 1)

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  handleAddData = () => {
    const { value: query, onChange } = this.props

    const newQuery = [...query, {}]

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  importFromCSV = index => () => {
    const inputFileRef = this.inputFileRef

    inputFileRef.current.click()

    inputFileRef.current.onchange = async e => {
      const file = e.target.files[0]
      if (file.size > 102400) {
        alert('File is too big! Max size is 100kb')
        return
      }
      const reader = new FileReader()

      reader.onload = async e => {
        const text = e.target.result
        const rows = text.split('\n')
        const delimiter = this.detectDelimiterByHeader(rows[0])
        const headers = rows[0].split(delimiter)

        const data = rows
          .slice(1)
          .map(row => row.split(delimiter))
          .map(row => {
            const obj = {}
            headers.forEach((header, i) => {
              obj[header] = row[i]
            })
            return obj
          })
          .filter(row => Object.values(row).filter(Boolean).length > 0)

        if (data.length > 1) {
          alert('Only one row is allowed (header row is not counted)')
          return
        }

        this.handleValueChange(index)(data[0])
      }

      reader.readAsText(file)
    }
  }

  clearJSON = index => () => {
    this.handleValueChange(index)({})
  }

  sortByModel = () => {}

  render() {
    let { classes, className, value = [{}], label, disabled: queryDisabled } = this.props

    if (!value) value = [{}]
    if (!Array.isArray(value)) value = [value || {}]

    const { optionsActions, optionsModels } = this.state

    const optionsLoaded = !!optionsActions && !!optionsModels

    return (
      <div className={classNames(classes.root, className)}>
        <InputLabel style={{ position: 'inherit' }} shrink>
          {label}
        </InputLabel>
        {insertBetween(
          value.map((item, i) => {
            let { collection, action, value } = item

            let disabled = !optionsLoaded

            return (
              <Grid
                container
                spacing={16}
                alignItems={'center'}
                className={classes.condition}
                key={`test_prepare_data_${i}`}
              >
                <Grid item xs={6}>
                  <DropDown
                    onChange={this.handleCollNameChange(i)}
                    disabled={disabled}
                    value={collection}
                    options={optionsModels}
                    label={'Where to create/update/delete new data ~[collection]'}
                  />
                </Grid>

                <Grid item xs={5}>
                  <DropDown
                    onChange={this.handleActionChange(i)}
                    disabled={disabled}
                    value={action}
                    options={optionsActions}
                    label={'Action ~[action]'}
                  />
                </Grid>

                <Grid item xs={1}>
                  <IconButton onClick={this.handleRemoveData(i)} disabled={disabled}>
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Данные для создания новой записи ~[value]
                  </Typography>
                  <Button
                    className={classes.addButton}
                    variant={'outlined'}
                    style={{ marginRight: '10px' }}
                    size="small"
                    onClick={this.importFromCSV(i)}
                  >
                    Import from CSV
                  </Button>
                  <Button
                    className={classes.addButton}
                    variant={'outlined'}
                    size="small"
                    onClick={this.clearJSON(i)}
                  >
                    Clear
                  </Button>
                  <CustomJSON
                    helperText={'test'}
                    value={value}
                    onUpdate={this.handleValueChange(i)}
                  />
                </Grid>
              </Grid>
            )
          }),
          i => (
            <Typography key={`and_${i}`} className={classes.and}>
              <b>AND</b>
            </Typography>
          ),
        )}

        {!queryDisabled && (
          <Button className={classes.addButton} variant={'outlined'} onClick={this.handleAddData}>
            Add Data
          </Button>
        )}
        <input ref={this.inputFileRef} type="file" accept="text/csv" style={{ display: 'none' }} />
      </div>
    )
  }
}

export default withApi(withStyles(styles)(AutoTestJson))
