import { Quill } from 'react-quill'

let BlockEmbed = Quill.import('blots/block/embed')
class VideoBlot extends BlockEmbed {
  static create(value) {
    let node = super.create()

    const source = document.createElement('source')
    node.controls = true
    Object.assign(node.style, {
      maxHeight: '300px',
      maxWidth: '300px',
    })

    node.setAttribute('alt', value.alt)
    node.setAttribute('src', value.src)
    node.setAttribute('data_object_id', value.data_object_id)

    node.appendChild(source)

    return node
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      src: node.getAttribute('src'),
      data_object_id: node.getAttribute('data_object_id'),
    }
  }
}

VideoBlot.blotName = 'video'
VideoBlot.tagName = 'video'

export default VideoBlot
