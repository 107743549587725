import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import SystemPageTemplate from '~shared/components/templates/SystemPageTemplate/index'
import PaperBox from '~shared/components/atoms/PaperBox'
import FormElementsGroup from '~shared/components/organisms/FormElementsGroup'
import Colors from '~shared/assets/styles/colors'
import { breakLine } from '~shared/utils'
import Icon from '@material-ui/core/Icon/Icon'
import PendingButton from '~shared/components/atoms/PendingButton/PendingButton'

class ResetPasswordPage extends Component {
  state = {}

  render() {
    const { classes, title, text, elements, buttonLabel, bottomText } = this.props
    const { isSubmitting, externalErrors } = this.state

    return (
      <SystemPageTemplate title={title} className={classes.container}>
        {elements && (
          <PaperBox className={classes.box} disabled={isSubmitting}>
            <Typography className={classes.textField}>{breakLine(text)}</Typography>
            <form noValidate className={classes.fullWidth}>
              <FormElementsGroup
                externalErrors={externalErrors}
                elements={elements}
                innerRef={ref => {
                  this._fieldsRef = ref
                }}
              />
              <div align="center">
                <PendingButton
                  type="submit"
                  variant="outlined"
                  pending={isSubmitting}
                  color="secondary"
                  className={classes.button}
                  onClick={this._handleButtonClick}
                >
                  {buttonLabel}
                </PendingButton>
              </div>
            </form>
            <Divider className={classes.fullWidth} />
            <Grid container className={classes.footer}>
              <Grid item xs={2}>
                <Icon style={{ color: Colors.DarkGrey }}>sms_failed</Icon>
              </Grid>
              <Grid item xs={9}>
                <Typography>{bottomText}</Typography>
              </Grid>
            </Grid>
          </PaperBox>
        )}
      </SystemPageTemplate>
    )
  }

  _handleButtonClick = async event => {
    event.preventDefault()

    if (!this._fieldsRef || !this._fieldsRef.checkValidity()) {
      return
    }

    const { api, onSuccess } = this.props
    const { request } = api.resetPassword({ form: this._fieldsRef.getValues() })

    let externalErrors

    this.setState({ isSubmitting: true })

    let success = false

    try {
      await request
      success = true
    } catch (error) {
      error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.inputError &&
        (externalErrors = {
          [error.response.data.error.inputError[0]]: error.response.data.error.message,
        })
    }

    this.setState({ isSubmitting: false, externalErrors })

    success && onSuccess && onSuccess()
  }
}

const styles = ({ spacing: { unit } }) => ({
  orText: {
    fontStyle: 'italic',
    paddingTop: unit * 2,
    paddingBottom: unit,
  },
  fullWidth: {
    width: '100%',
  },
  button: {
    marginTop: unit,
    marginBottom: unit * 2,
  },
  textField: {
    marginBottom: unit,
  },
  footer: {
    marginTop: unit * 2,
  },
  box: {
    paddingBottom: unit * 2,
  },
  container: {
    height: '100%',
  },
})

export default withStyles(styles)(ResetPasswordPage)
