import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { withApi } from '~shared/api/ApiContext'
import TextField from '@material-ui/core/TextField'
import DropDown from './DropDown'

const styles = () => ({
  root: {},
  select: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  textField: {
    minHeight: 34,
    '& > *': {
      minHeight: 34,
    },
  },
  and: {
    margin: `4px 0`,
  },
  addButton: {
    marginTop: 5,
  },
})

class SurveyQuestionEnumOptions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {
    const {
      api,
      document: { _id, origin },
    } = this.props

    let { request } = api.getSurveyQuestionTargetOptions(origin || _id)

    let otherQuestions
    try {
      otherQuestions = await request
    } catch (err) {
      return
    }

    this.setState({ otherQuestions })
  }

  _getAnswerByIndex = index => {
    return this.getSanitizedValue()[index]
  }

  _updateAnswer = (index, data) => {
    let condition = this._getAnswerByIndex(index)

    const value = [...this.getSanitizedValue()]
    condition = { ...condition, ...data }
    value[index] = condition

    const { onChange } = this.props

    onChange({
      target: {
        value,
        name: this.props.name,
      },
    })
  }

  handleAnswerChange = index => e => {
    const { value } = e.target
    const {
      selectedLanguage: { id },
    } = this.props
    const field = `value__${id}`
    this._updateAnswer(index, { [field]: value })
  }

  handleAnswerTargetChange = index => e => {
    const { value } = e.target
    this._updateAnswer(index, { target: value })
  }

  handleRemoveAnswer = index => () => {
    const { onChange } = this.props

    const value = [...this.getSanitizedValue()]
    value.splice(index, 1)

    onChange({
      target: {
        value,
        name: this.props.name,
      },
    })
  }

  handleAddAnswer = () => {
    const { onChange } = this.props

    const value = [...this.getSanitizedValue(), {}]

    onChange({
      target: {
        value,
        name: this.props.name,
      },
    })
  }

  getSanitizedValue = () => {
    let {
      value = [],
      settings: { minItems = 0 },
    } = this.props

    if (value.length < minItems) value = [...value, ...new Array(minItems - value.length).fill({})]

    return value
  }

  render() {
    let {
      selectedLanguage,
      classes,
      className,
      label,
      disabled,
      settings: { yesno = false, minItems = 0, maxItems = 99 },
    } = this.props

    const { otherQuestions } = this.state

    const value = this.getSanitizedValue()

    const disabledRemoving = value.length === minItems

    return (
      <div className={classNames(classes.root, className)}>
        <InputLabel style={{ position: 'inherit' }} shrink>
          {label}
        </InputLabel>

        {value.map((item, i) => {
          const { id, target } = item
          const valueField = `value__${selectedLanguage.id}`

          return (
            <Grid container key={i} spacing={8}>
              <Grid item xs={12} md={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={yesno ? (i === 0 ? 'Yes' : 'No') : `Answer ${i + 1}`}
                  fullWidth
                  disabled={disabled}
                  className={classes.textField}
                  key={id}
                  value={item[valueField]}
                  onChange={this.handleAnswerChange(i)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <div style={{ display: 'flex' }}>
                  <DropDown
                    label={'Target'}
                    disabled={disabled}
                    value={target}
                    options={otherQuestions}
                    onChange={this.handleAnswerTargetChange(i)}
                  />

                  <IconButton
                    onClick={this.handleRemoveAnswer(i)}
                    disabled={disabled || disabledRemoving}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          )
        })}
        {value.length < maxItems && (
          <Button
            className={classes.addButton}
            disabled={disabled}
            variant={'outlined'}
            onClick={this.handleAddAnswer}
          >
            Add Answer
          </Button>
        )}
      </div>
    )
  }
}

export default withApi(withStyles(styles)(SurveyQuestionEnumOptions))
