import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Icon,
} from '@material-ui/core'
import Colors from '~shared/assets/styles/colors'
import { UNIT } from '~shared/assets/styles/theme'

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
  },
  table: {
    flex: 1,
    backgroundColor: Colors.White,
    '& td': {
      minWidth: 30,
    },
  },
  addRowButton: {
    width: '100%',
  },
  cell: {
    position: 'relative',
    minWidth: 100,
  },
  textField: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100%',
    boxSizing: 'border-box',
    paddingLeft: 'inherit',
    paddingRight: 'inherit',
  },
  input: {
    height: '100%',
  },
}

const TableButton = ({ style, disabled, onClick, icon }) => (
  <IconButton size={'small'} style={style} disabled={disabled} onClick={onClick}>
    <Icon fontSize={'small'}>{icon}</Icon>
  </IconButton>
)

const handleTextFieldKeyDown = e => {
  e.key === 'Enter' && e.target.blur()
}

const JsonTable = withStyles(styles)(
  ({ style, className, classes, name, value = [['...']], onChange, disabled }) => {
    const [tableRows, setTableRows] = useState(value)

    useEffect(() => {
      if (value === tableRows) return

      setTableRows(value)
    }, [value])

    useEffect(() => {
      if (tableRows === value) return

      onChange &&
        onChange({
          target: {
            name,
            value: tableRows,
          },
        })
    }, [tableRows])

    const handleAddColumn = useCallback(() => setTableRows(tv => tv.map(r => [...r, ''])), [])
    const handleAddRow = useCallback(() => setTableRows(tv => [...tv, tv[0].map(() => '')]), [])

    const handleDeleteColumn = useCallback(
      index => setTableRows(tv => tv.map(r => r.filter((c, ci) => ci !== index))),
      [],
    )
    const handleDeleteRow = useCallback(
      index => setTableRows(tv => tv.filter((r, ri) => ri !== index)),
      [],
    )

    const handleCellChange = useCallback(
      (rowIndex, cellIndex) => ({ target: { value } }) => {
        if (tableRows[rowIndex][cellIndex] === value) return

        const updateRows = tableRows.map(r => [...r])
        updateRows[rowIndex][cellIndex] = value
        setTableRows(updateRows)
      },
      [tableRows],
    )

    const deleteColumnDisabled = disabled || tableRows[0].length < 3
    const deleteRowDisabled = disabled || tableRows.length < 3

    const content = useMemo(
      () => [
        [
          '',
          ...tableRows[0].map((c, i) => [
            <TableButton
              style={{ marginLeft: -UNIT * 1.5 }}
              disabled={deleteColumnDisabled}
              onClick={() => handleDeleteColumn(i)}
              icon="delete"
            />,
          ]),
          <TableButton
            style={{ marginLeft: -UNIT * 1.5 }}
            disabled={disabled}
            onClick={handleAddColumn}
            icon="add"
          />,
        ],
        ...tableRows.map((r, ri) => [
          <TableButton
            disabled={deleteRowDisabled}
            onClick={() => handleDeleteRow(ri)}
            icon="delete"
          />,
          ...r.map((c, ci) => (
            <TextField
              classes={{ root: classes.textField }}
              InputProps={{ classes: { root: classes.input } }}
              defaultValue={c}
              disabled={disabled}
              placeholder={'Add content...'}
              onBlur={handleCellChange(ri, ci)}
              onKeyDown={handleTextFieldKeyDown}
              fullWidth
            />
          )),
          '',
        ]),
        [
          <TableButton disabled={disabled} onClick={handleAddRow} icon="add" />,
          ...tableRows[0].map(() => ''),
          '',
        ],
      ],
      [
        disabled,
        tableRows,
        handleAddRow,
        handleAddColumn,
        handleCellChange,
        deleteColumnDisabled,
        deleteRowDisabled,
        classes,
      ],
    )

    const headerCells = (content.slice(0, 1)[0] || []).map((c, i) => (
      <TableCell
        key={i}
        {...(i === 0 || i === content[0].length - 1 ? { width: 30, align: 'center' } : {})}
      >
        {c}
      </TableCell>
    ))
    const rows = content.slice(1).map(r => (
      <TableRow key={Math.random()}>
        {(r || []).map((c, i) => (
          <TableCell key={i} classes={{ root: classes.cell }}>
            {c}
          </TableCell>
        ))}
      </TableRow>
    ))

    return (
      <div style={style} className={classNames(className, classes.root)}>
        <Table className={classes.table} padding={'dense'}>
          <TableHead>
            <TableRow>{headerCells}</TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </div>
    )
  },
)

export default JsonTable
