import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import { default as MuiTableBody } from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Checkbox from '@material-ui/core/Checkbox'
import TableCell from '@material-ui/core/TableCell'
import { CustomTableField } from '~components/atom/FieldFactory/CustomTable'
import { Link } from 'react-router-dom'
import { getIconAndColorByStatus } from './StateHelper'
import Typography from '@material-ui/core/Typography'
import ColorDrop from '~components/atom/FieldFactory/CustomColor/ColorDrop'
import ReferenceChip from '~components/atom/ReferenceChip'
import memoizeOne from 'memoize-one'
import moment from 'moment'

class TableBody extends React.PureComponent {
  _renderReference = (value, settings, key, type) => {
    const { classes } = this.props

    return (
      <ReferenceChip
        style={type}
        key={key}
        className={classes.chip}
        variant="small"
        settings={settings}
        value={value}
      />
    )
  }

  renderValue = (value, col) => {
    const { classes } = this.props

    let { type } = col

    if (type === 'table') {
      return <Icon color={'disabled'}>table_chart</Icon>
    }

    if (value === undefined || value === null || /^\s*$/.test(value)) return ''

    switch (type) {
      case 'text':
      case 'email':
      case 'integer':
      case 'float':
        break
      case 'enum':
        let originalValue = value
        const { settings: { variant } = {}, options } = col

        const option = options.find(o => o.value === value) || {}
        value = option.label

        switch (variant) {
          case 'icon':
            value = (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ marginRight: 10 }}>{originalValue}</Icon>
                {value}
              </span>
            )
            break
          case 'status':
            const { icon, color } = getIconAndColorByStatus(originalValue)
            value = (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {icon ? (
                  <Icon title={value} style={{ color }}>
                    {icon}
                  </Icon>
                ) : (
                  value
                )}
              </span>
            )
            break
          default:
            break
        }
        break
      case 'table':
        value = <Icon color="disabled">table_chart</Icon>
        break
      case 'password':
        value = <Typography style={{ color: 'rgba(0,0,0,0.25)' }}>●●●●●●</Typography>
        break
      case 'richtext':
        value = <Icon color="disabled">text_fields</Icon>
        break
      case 'json':
        value = <Icon color="disabled">code</Icon>
        break
      case 'media':
        value = <Icon color="disabled">photo</Icon>
        break
      case 'date':
        value = moment(value).format('DD/MM/YYYY')
        break
      case 'datetime':
      case 'timestamp':
        // converting 10 digit format to 13 one
        if (value && value.toString().length === 10) {
          value = (Number(value) || 0) * 1000
        }

        value = moment(value).format('DD/MM/YYYY HH:mm:ss')
        break
      case 'color':
        value = <ColorDrop color={value} className={classes.colorDrop} />
        break
      case 'multiple':
        if (Array.isArray(value)) {
          value = value.join(', ')
        }
        break
      case 'reference':
      case 'vreference':
        if (Array.isArray(value)) {
          value = value.map((value, i) => this._renderReference(value, col.settings, i, type))
        } else {
          value = this._renderReference(value, col.settings, 0, type)
        }
        break
      case 'boolean':
        value = <Icon color={value ? 'primary' : 'disabled'}>{value ? 'check' : 'close'}</Icon>
        break
      case 'usermedia':
        value = <Icon color="disabled">image</Icon>
        break
      default:
        value = <Icon color="error">warning</Icon>
        break
    }

    return value
  }

  getKeyedInlineEditableColumns = memoizeOne(() => {
    let { inlineEditableColumns, columnData } = this.props

    if (inlineEditableColumns) {
      if (inlineEditableColumns === 'all') {
        inlineEditableColumns = columnData.reduce(
          (data, { name: columnName }) => ({
            ...data,
            [columnName]: true,
          }),
          {},
        )
      } else {
        inlineEditableColumns = inlineEditableColumns.reduce(
          (data, columnName) => ({
            ...data,
            [columnName]: true,
          }),
          {},
        )
      }
    } else {
      inlineEditableColumns = {}
    }

    return inlineEditableColumns
  })

  render() {
    const {
      classes,
      columnData,
      rowData,
      isRowDeletable,
      isRowDuplicatable,
      isRowSelectable,
      selectedColumns,
      selectedLanguage,
      onInlineChange,
      selected,
      onDeleteRow,
      onDuplicateRow,
      onToggleRow,
      onInlineFieldClick,
      detailUrl,
      isCMS,
    } = this.props

    const inlineEditableColumns = this.getKeyedInlineEditableColumns()

    return (
      <MuiTableBody>
        {rowData &&
          rowData.map(row => {
            let isSelected = selected && selected.indexOf(row._id) >= 0
            const { publishedAt, _id, enabled } = row
            let href = detailUrl ? detailUrl.replace('[id]', _id) : undefined
            const disableSystemic = ['systemic', 'system'].includes(row.type)

            let CMSStyle = ''
            if (isCMS) {
              if (!publishedAt) {
                CMSStyle = classes.draft
              } else {
                CMSStyle = classes.published
              }
              if (enabled === false) {
                CMSStyle = classes.notVisible
              }
            }

            return (
              <TableRow
                component={href ? Link : undefined}
                to={href}
                id={row._id}
                hover
                tabIndex={-1}
                key={row._id}
                className={CMSStyle}
              >
                {isRowDeletable && (
                  <TableCell padding="none">
                    <IconButton id={row._id} onClick={onDeleteRow} disabled={disableSystemic}>
                      <Icon>delete</Icon>
                    </IconButton>
                  </TableCell>
                )}

                {isRowDuplicatable && (
                  <TableCell padding="none">
                    <IconButton id={row._id} onClick={onDuplicateRow} disabled={disableSystemic}>
                      <Icon>filter_none</Icon>
                    </IconButton>
                  </TableCell>
                )}

                {isRowSelectable && (
                  <TableCell id={row._id} padding="none" onClick={onToggleRow}>
                    <Checkbox checked={isSelected} />
                  </TableCell>
                )}

                {columnData.map(col => {
                  let { name, settings: { multilang } = {} } = col

                  if (!selectedColumns.includes(col.name)) {
                    return null
                  }

                  let isInlineEditable = inlineEditableColumns && col.name in inlineEditableColumns

                  if (multilang) name += `__${selectedLanguage.id}`

                  const cellStyle =
                    multilang && selectedLanguage && selectedLanguage.rtl
                      ? { textAlign: 'unset' }
                      : undefined
                  const cellDir =
                    multilang && selectedLanguage && selectedLanguage.rtl ? 'rtl' : undefined

                  if (isInlineEditable) {
                    let value = row[name]
                    if (value === undefined) value = col.defaultValue

                    let item = { ...col, value }

                    return (
                      <CustomTableField
                        onClick={onInlineFieldClick}
                        row={row}
                        col={col}
                        item={item}
                        key={`${row._id}_${col.name}`}
                        dir={cellDir}
                        style={cellStyle}
                        onChange={onInlineChange}
                      />
                    )
                  }

                  return (
                    <TableCell
                      className={classes.cell}
                      padding="dense"
                      key={col.name}
                      dir={cellDir}
                      style={cellStyle}
                    >
                      {this.renderValue(row[name], col, null)}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
      </MuiTableBody>
    )
  }
}

export default TableBody
