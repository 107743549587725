import React from 'react'
import ClassNames from 'classnames'
import moment from 'moment'
import DatePicker from 'material-ui-pickers/DatePicker'
import { styles as dayStyles } from 'material-ui-pickers/DatePicker/components/Day'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'

const styles = theme => {
  const base = dayStyles(theme)
  return {
    ...base,
    root: {},
    day: {
      ...base.day,
      margin: 0,
      width: '40px',
      borderRadius: '0',
    },
    beginCap: {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    endCap: {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
    iconOnly: {
      verticalAlign: 'inherit',
      left: '-6px',
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '& > div': {
          margin: 0,
          height: 'auto',
          '& > button': {
            padding: 3,
          },
        },
      },
      '& input': {
        display: 'none',
      },
    },
    disabled: {
      '& button': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
  }
}

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      begin: props.value && props.value[0],
      end: props.value && props.value[1],
      hover: null,
    }
    this.picker = React.createRef()
    this.min = Math.min(this.state.begin, this.state.end || this.state.hover)
    this.max = Math.max(this.state.begin, this.state.end || this.state.hover)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if(!prevProps.open && this.props.open && this.picker && this.picker.current){
      this.picker.current.open()
    }
  }

  getFinalDates = () => {
    const { begin, end } = this.state
    const dates = begin && end && [begin, end].sort((a, b) => a.valueOf() - b.valueOf())
    return dates
  }

  getFinalDatesWithOneDayPick = () => {
    let { begin, end } = this.state

    if (begin && !end) {
      end = moment(begin)
      this.setState({
        end
      })
    }
    const dates = begin ? [begin, end] : null
    return dates
  }

  renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    const { begin, end } = this.state
    const { autoOk, classes, iconOnly, onChange = () => {} } = this.props

    return React.cloneElement(dayComponent, {
      onClick: e => {
        e.stopPropagation()
        if (!begin) {
          this.setState({
            begin: day,
          })
        } else if (!end) {
          this.setState({ end: day }, () => {
            if (autoOk) {
              const dates = this.getFinalDates()
              onChange(dates) // return value to the parent
              this.picker.current.close()
            }
          })
        } else {
          this.setState({
            begin: day,
            end: undefined,
          })
        }
      },
      onMouseEnter: e => this.setState({ hover: day }),
      className: ClassNames(classes.day, {
        root: iconOnly ? classes.iconOnly : '',
        [classes.hidden]: dayComponent.props.hidden,
        [classes.current]: dayComponent.props.current,
        [classes.isDisabled]: dayComponent.props.disabled,
        [classes.isSelected]: day >= this.min && day <= this.max,
        [classes.beginCap]: moment(day).isSame(this.min),
        [classes.endCap]: moment(day).isSame(this.max),
      }),
    })
  }

  onRangeClose = () => {
    const { onClose = () => {}, onChange = () => {}, canOneDayPick } = this.props
    const dates = canOneDayPick && !this.state.end ? this.getFinalDatesWithOneDayPick() : this.getFinalDates()
    if (dates) onChange(dates)
    if (onClose) onClose()
  }

  onRangeClear = () => {
    const { onClear = () => {} } = this.props
    this.setState({
      begin: undefined,
      end: undefined,
      hover: undefined,
      onChange: [],
    })
    onClear()
  }

  rangeLabelFunc = (date, invalid) => {
    const { labelFunc, format, emptyLabel } = this.props
    const { begin, end } = this.state
    const dates = this.getFinalDates()

    if (labelFunc) {
      return labelFunc(dates, invalid)
    }
    if (date && begin && end) {
      return `${moment(begin).format(format)} - ${moment(end).format(format)}`
    }
    return emptyLabel || ''
  }

  render() {
    const { classes, iconOnly, ...props } = this.props
    const { begin, end, hover } = this.state
    const iconColor = begin || end ? '' : classes.disabled

    // calculates the days to change the background color
    this.min = Math.min(begin, end || hover)
    this.max = Math.max(begin, end || hover)

    return (
      <DatePicker
        {...props}
        classes={iconOnly ? { root: ClassNames(classes.iconOnly, iconColor) } : {}}
        value={begin}
        renderDay={this.renderDay}
        onClose={this.onRangeClose}
        onChange={() => {}}
        onClear={this.onRangeClear}
        ref={this.picker}
        labelFunc={this.rangeLabelFunc}
      />
    )
  }
}

DateRangePicker.propTypes = {
  autoOk: PropTypes.bool,
  classes: PropTypes.object,
  iconOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onClear: PropTypes.func,
  labelFunc: PropTypes.func,
  format: PropTypes.string,
  emptyLabel: PropTypes.string,
  canOneDayPick: PropTypes.bool
}

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker)
