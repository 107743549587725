import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { withApi } from '~shared/api/ApiContext'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import FormControl from '@material-ui/core/FormControl'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withLanguage } from '~src/LanguageContext'

import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import ReferenceChip, { translateItem } from '~components/atom/ReferenceChip'

const sortItemStyles = {
  root: {
    listStyle: 'none',
    marginBottom: '1px',
  },
}

const sortListStyles = {
  root: {
    padding: 0,
    margin: 0,
  },
}

const SortableItem = withStyles(sortItemStyles)(
  SortableElement(({ value, onDelete, classes, sortable, settings, type }) => {
    return (
      <li className={classes.root}>
        <ReferenceChip
          style={type}
          sortable={sortable}
          value={value}
          onDelete={onDelete}
          settings={settings}
        />
      </li>
    )
  }),
)

const SortableList = withStyles(sortListStyles)(
  SortableContainer(({ items, onDelete, classes, sortable, settings, type }) => {
    return (
      <ul className={classes.root}>
        {items.map((value, index) => (
          <SortableItem
            type={type}
            key={`item-${index}`}
            index={index}
            value={value}
            onDelete={onDelete && onDelete(value, index)}
            sortable={sortable}
            settings={settings}
          />
        ))}
      </ul>
    )
  }),
)

class CustomReference extends React.Component {
  state = {
    inputValue: '',
    selectedItem: [],
    isLoading: false,
    suggestions: null,
    popperOpen: false,
  }

  _findSuggestions = async () => {
    const { api, selectedLanguage } = this.props

    this.setState({ isLoading: true })

    const { settings: { type, collection, filterCollections } = {} } = this.props

    let result

    try {
      const { request } = api.getReferenceSuggestions(collection, type, filterCollections)
      const response = await request
      result = response.result
      result = result.map(item => translateItem(item, selectedLanguage))
    } catch (err) {
    } finally {
      this.setState({ isLoading: false, suggestions: result })
    }
  }

  _handleInputChange = ({ target: { value } }) => {
    this.setState({ inputValue: value })
  }

  _handleChange = item => {
    let { settings: { max = 1 } = {} } = this.props
    let value = this.sanitizedValue()

    if (value.length >= max) {
      return
    }

    value = [...value, item]

    this.updateValue(value)

    this.setState({
      inputValue: '',
    })

    return value
  }

  _handleDelete = (itemToDelete, index) => () => {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    let value = this.sanitizedValue()
    value = [...value]
    value.splice(index, 1)

    this.updateValue(value)
  }

  updateValue = value => {
    let { name, onChange } = this.props
    onChange({
      target: {
        name,
        value,
      },
    })
  }

  isMulti = () => {
    let { settings: { max = 1 } = {} } = this.props
    return max > 1
  }

  getSuggestions(inputValue) {
    if (this.state.suggestions === null || this.state.suggestions === undefined) {
      return null
    }

    return this.state.suggestions.filter(suggestion => {
      const { _name, title, _id } = suggestion
      return (_name || title || _id).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    })
  }

  sanitizedValue = () => {
    const { value } = this.props

    const isEmpty = !value || value === ''

    return Array.isArray(value) ? value : isEmpty ? [] : [value]
  }

  render() {
    let { popperOpen, inputValue, value } = this.state
    const {
      classes,
      disabled,
      label,
      sortable = true,
      settings,
      isInlined,
      helperText: hText,
      required,
      type,
    } = this.props
    const { disableCreatingItem, disableFindingItem, max = 1 } = settings || {}
    const helperText = isInlined ? undefined : hText ? hText + ` (max: ${max})` : `max: ${max}`

    value = value || this.sanitizedValue()

    const suggestions = this.getSuggestions(inputValue)

    return (
      <FormControl fullWidth required={required}>
        <InputLabel style={{ position: 'inherit' }} disabled={disabled} shrink>
          {label}
        </InputLabel>

        <div ref={ref => (this.popperNode = ref)}>
          <SortableList
            type={type}
            sortable={this.isMulti() && value.length > 1 && sortable && !disabled}
            items={value}
            settings={settings}
            onDelete={!disabled && this._handleDelete}
            onSortEnd={this._handleSortEnd}
            useDragHandle
            lockAxis={'y'}
            distance={10}
          />

          {!disabled && (!disableCreatingItem || !disableFindingItem) && value.length < max && (
            <Chip
              style={{ display: 'flex', justifyContent: 'space-between' }}
              label={
                disableCreatingItem
                  ? 'Find item'
                  : disableFindingItem
                  ? 'Create item'
                  : 'Create or find item'
              }
              clickable
              deleteIcon={<Icon>add_circle</Icon>}
              onDelete={this._handleCreateOrFindClick}
              onClick={this._handleCreateOrFindClick}
            />
          )}

          {disabled && value.length === 0 && (
            <Chip
              variant="outlined"
              style={{ display: 'flex', justifyContent: 'space-between' }}
              label={'Empty'}
            />
          )}
        </div>

        <Popper
          style={{ zIndex: 9999 }}
          placement="bottom"
          open={popperOpen}
          anchorEl={this.popperNode}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
        >
          <ClickAwayListener onClickAway={this._handleClickAway}>
            <Paper
              // square
              className={classes.paper}
              style={{
                width: this.popperNode ? this.popperNode.clientWidth : null,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                <TextField
                  autoFocus
                  fullWidth
                  value={inputValue}
                  onChange={this._handleInputChange}
                  placeholder={'Type to filter ...'}
                  InputProps={{
                    style: { padding: `7px 15px`, boxSizing: 'border-box' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>search</Icon>
                      </InputAdornment>
                    ),
                    autoComplete: 'off',
                  }}
                />
              </div>

              {this.state.isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div className={classes.itemsContainer}>
                  {suggestions &&
                    (suggestions.length === 0 ? (
                      <MenuItem>No results found</MenuItem>
                    ) : (
                      suggestions
                        .slice(0, 200)
                        .map((suggestion, index) => (
                          <SuggestionItem
                            onClick={this._handleSuggestionClick(suggestion)}
                            key={index}
                            suggestion={suggestion}
                            index={index}
                          />
                        ))
                    ))}
                </div>
              )}
              {!(disableCreatingItem || settings.disableCreatingItem) && (
                <div>
                  <Button
                    onClick={this._handleCreateClick}
                    variant="contained"
                    fullWidth
                    classes={{ label: classes.createNew }}
                  >
                    <Icon>add_circle</Icon>
                    <span>Create new</span>
                  </Button>
                </div>
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>
        {helperText && <FormHelperText disabled={disabled}>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

  _handleSortEnd = ({ oldIndex, newIndex }) => {
    let value = this.sanitizedValue()
    value = arrayMove(value, oldIndex, newIndex)

    this.updateValue(value)
  }

  _handleCreateOrFindClick = e => {
    e.preventDefault()
    e.stopPropagation()

    const { popperOpen } = this.state
    const { disabled, settings } = this.props
    const { disableFindingItem } = settings || {}

    if (disabled) return

    if (disableFindingItem) {
      return this._handleCreateClick()
    }

    if (!popperOpen && this.state.suggestions === null) {
      this._findSuggestions()
    }

    this.setState({ popperOpen: !popperOpen })
  }

  _handleCreateClick = async () => {
    const { api, settings: { collection, type } = {}, onRequestSave, name } = this.props

    if (collection) {
      const { request } = api.connector.post(
        `/@/create/${collection}?context=reference`,
        type ? { data: { type } } : {},
      )

      const value = this.sanitizedValue()
      const newItem = { publishedAt: null }

      this.setState({ value: [...value, newItem] })

      // this._handleChange(newItem)

      const { result: _id, url } = await request

      onRequestSave &&
        (await onRequestSave({
          name,
          value: [...value, { _id }],
        }))

      this.setState({ value: null })

      url && api.connector.onNavigateTo(url)
    }
    this.setState({ popperOpen: false })
  }

  _handleClickAway = () => {
    this.setState({ popperOpen: false })
  }

  _handleSuggestionClick = suggestion => () => {
    this.setState({
      popperOpen: false,
    })

    this._handleChange(suggestion)
  }
}

const styles = ({ spacing: { unit } }) => ({
  paper: {
    height: 300,
  },
  progress: {
    height: 2,
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  createNew: {
    justifyContent: 'flex-start',
    '& > *:not(:last-child)': {
      marginLeft: -unit * 0.5,
      marginRight: unit * 0.5,
    },
  },
  itemsContainer: {
    flex: 1,
    overflowY: 'auto',
    '-ms-overflow-style': 'scrollbar',
    '&>*': {
      display: 'block',
      boxSizing: 'border-box',
      height: 'auto',
    },
  },
})

export default withLanguage(withStyles(styles)(withApi(CustomReference)))

const SuggestionItem = ({ suggestion, ...rest }) => {
  const { _name, title, _id } = suggestion
  return <MenuItem {...rest}>{_name || title || _id}</MenuItem>
}
