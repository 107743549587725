import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import { withApi } from '~shared/api/ApiContext'
import React from 'react'
import Colors from '~shared/assets/styles/colors'

const styles = () => ({
  btn: {
    transaction: 'all 0.3s ease',
  },
  success: {
    backgroundColor: Colors.GrassGreen,
  },

  error: {
    backgroundColor: Colors.Red,
  },
})

const CustomButtonCopyPaste = props => {
  const { onChange, document, classes } = props

  const prepareData = document => {
    const doc = { ...document }
    delete doc._id
    delete doc.__v
    delete doc.createdAt
    delete doc.updatedAt
    delete doc.createdBy
    delete doc.updatedBy
    delete doc.ident
    delete doc.firstCheckMethod
    delete doc.secondCheckMethod
    delete doc.secondCheckResult

    delete doc.name
    delete doc.method

    return doc
  }

  const setClassToBtn = (btn, className) => {
    btn.classList.add(className)
    setTimeout(() => {
      btn.classList.remove(className)
    }, 1000)
  }

  const copy = async e => {
    const target = e.currentTarget
    const doc = prepareData(document)
    navigator.clipboard
      .writeText(JSON.stringify(doc))
      .then(() => {
        setClassToBtn(target, classes.success)
      })
      .catch(() => {
        setClassToBtn(target, classes.error)
      })
  }

  const paste = e => {
    const target = e.currentTarget

    navigator.clipboard
      .readText()
      .then(clipText => {
        const doc = prepareData(JSON.parse(clipText))
        onChange({
          target: {},
          all: doc,
        })
        setClassToBtn(target, classes.success)
      })
      .catch(() => {
        setClassToBtn(target, classes.error)
      })
  }

  return (
    <div>
      <Button
        variant={'outlined'}
        style={{ marginRight: '10px' }}
        size="small"
        className={classes.btn}
        onClick={copy}
      >
        Copy Before/After
      </Button>
      <Button
        variant={'outlined'}
        style={{ marginRight: '10px' }}
        size="small"
        className={classes.btn}
        onClick={paste}
      >
        Paste Before/After
      </Button>
    </div>
  )
}

export default withApi(withStyles(styles)(CustomButtonCopyPaste))
