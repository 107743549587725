import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import CustomReference from './CustomReference'

const styles = theme => ({
  listItemChip: {
    backgroundColor: 'red',
  },
})

class CustomVReference extends React.Component {
  render() {
    const { classes, settings } = this.props
    return (
      <CustomReference
        {...this.props}
        settings={{ ...settings, disableCreatingItem: true }}
        classes={{ listItemChip: classes.listItemChip }}
      />
    )
  }
}

export default withStyles(styles)(CustomVReference)
