import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Chip from '@material-ui/core/Chip/Chip'
import Popover from '@material-ui/core/Popover/Popover'
import Typography from '@material-ui/core/Typography/Typography'
import Button from '@material-ui/core/Button/Button'
import Divider from '@material-ui/core/Divider/Divider'
import Avatar from '@material-ui/core/Avatar/Avatar'
import Icon from '@material-ui/core/Icon/Icon'
import Colors from '~shared/assets/styles/colors'
import { withApi } from '~shared/api/ApiContext'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {},
  avatarChildren: {
    width: 'auto',
    height: 'auto',
  },
  chip: {
    color: Colors.Black,
    backgroundColor: fade(Colors.Black, 0.1),
    '&:hover': {
      color: palette.primary.main,
    },
  },
  avatar: {
    color: Colors.Black,
    backgroundColor: `${Colors.Grey0} !important`,
  },
  popover: {},
  content: {
    minWidth: 250,
    padding: unit,
  },
  button: {
    color: palette.error.main,
    '&:hover': {
      backgroundColor: fade(palette.error.main, 0.1),
    },
  },
})

class HeaderUserItem extends React.Component {
  state = {}

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleLogout = () => {
    const { api } = this.props
    api.logout()
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  render() {
    const {
      classes,
      member: { userRole, userName },
      className,
    } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <React.Fragment>
        <Chip
          className={className}
          classes={{ root: classes.chip, avatarChildren: classes.avatarChildren }}
          avatar={
            <Avatar className={classes.avatar}>
              <Icon>face</Icon>
            </Avatar>
          }
          label={userName}
          onClick={this.handleClick}
        />
        <Popover
          classes={{ paper: classes.popover }}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography className={classes.content}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>User Name:</td>
                  <td>{userName}</td>
                </tr>
                <tr>
                  <td>Role:</td>
                  <td>{userRole}</td>
                </tr>
              </tbody>
            </table>
          </Typography>
          <Divider />
          <Button
            onClick={this.handleLogout}
            classes={{ root: classes.button }}
            fullWidth
            color="secondary"
          >
            Logout
          </Button>
        </Popover>
      </React.Fragment>
    )
  }
}

export default withApi(withStyles(styles)(HeaderUserItem))
