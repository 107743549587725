import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon/Icon'
import Typography from '@material-ui/core/Typography/Typography'
import classNames from 'classnames'
import amber from '@material-ui/core/colors/amber'

import PointsBox from '~shared/components/atoms/PointsBox'
import { htmlTextAnchorParser } from '~shared/utils'

const styles = ({ breakpoints, spacing: { unit }, palette }) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 80,
  },
  circSuccess: {
    color: palette.secondary.main,
  },
  circError: {
    color: palette.error.main,
  },
  circWarning: {
    color: amber[700],
  },
  title: {
    marginTop: unit * 2,
  },
  message: {
    marginTop: unit * 1.5,
  },
  points: {
    marginTop: unit * 2,
  },
})

const iconByVariant = {
  success: 'check_circle',
  error: 'highlight_off',
}

class GenericMessageContent extends React.Component {
  render() {
    const { classes, className, style, variant, title, message, points } = this.props
    return (
      <div className={classNames(classes.root, className)} style={style}>
        <div
          className={classNames({
            [classes.circSuccess]: variant === 'success',
            [classes.circError]: variant === 'error',
            [classes.circWarning]: variant === 'warning',
          })}
        >
          <Icon className={classes.icon}>{iconByVariant[variant] || 'priority_high'}</Icon>
        </div>

        {title && <Typography variant="h3">{title}</Typography>}
        {message && (
          <Typography className={classes.message} variant="subtitle1">
            {htmlTextAnchorParser(message)}
          </Typography>
        )}
        {points && <PointsBox points={points} className={classes.points} />}
      </div>
    )
  }
}

export default withStyles(styles)(GenericMessageContent)
