import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DropDown from './DropDown'
import MultiInput from './MultiInput'
import { withApi } from '~shared/api/ApiContext'
import { insertBetween } from '~shared/utils'
import NumberField from '~shared/components/NumberField'
import CustomMultipleMenu from '~components/atom/FieldFactory/CustomMultipleMenu'
import CustomMenuField from '~components/atom/FieldFactory/CustomMenuField'
import TrueFalseSwitch from './TrueFalseSwitch'

const styles = () => ({
  root: {},
  nestedRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  select: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 34,
  },
  condition: {
    width: '100%',
    margin: 0,
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.1)',
  },
  nestedCondition: {
    padding: 10,
    margin: 10,
    boxSizing: 'border-box',
  },
  and: {
    margin: `4px 0`,
  },
  addButton: {
    marginTop: 5,
    flexGrow: 0,
    alignSelf: 'flex-start',
  },
})

class SegmentationQuery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {
    const { api } = this.props

    let { request } = api.getSegmentationOptions()

    let options
    let groupedOptions
    try {
      options = await request

      const clusters = []
      groupedOptions = options.reduce((accu, opt) => {
        if (!clusters.includes(opt.cluster)) {
          clusters.push(opt.cluster)
          accu.push({ value: opt.cluster, label: opt.cluster, disabled: true, isClusterName: true })
        }
        accu.push(opt.field)
        return accu
      }, [])
    } catch (err) {
      return
    }

    this.setState({ options, groupedOptions })
  }

  _getConditionByIndex = index => {
    const { value: query } = this.props
    return query[index]
  }

  _updateCondition = (index, data) => {
    const { value: query } = this.props

    let condition = this._getConditionByIndex(index)

    const newQuery = [...query]
    condition = { ...condition, ...data }
    newQuery[index] = condition

    const { onChange } = this.props

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  handleFieldChange = index => e => {
    const { options } = this.state
    const condition = this._getConditionByIndex(index)
    const { value } = e.target

    let data = {
      field: value,
      value: null,
    }

    const option = options.find(o => o.field === value)
    const { operators = [] } = option

    const { operator } = condition
    if (!operators.includes(operator)) data.operator = operators[0] ? operators[0].value : null

    this._updateCondition(index, data)
  }

  handleOperatorChange = index => e => {
    const { value } = e.target
    this._updateCondition(index, { operator: value })
  }

  handleValueChange = index => e => {
    const { value } = e.target

    this._updateCondition(index, { value })
  }

  handleNestedValueChange = index => e => {
    const { value, typeQuery, isDeleted } = e.target

    const condition = this._getConditionByIndex(index)
    const { type } = condition

    if (type !== 'block') return

    if (isDeleted) {
      return this.handleRemoveCondition(index)()
    }

    const newQuery = { ...condition }
    if (typeQuery) newQuery.typeQuery = typeQuery
    if (value) newQuery.query = value

    this._updateCondition(index, newQuery)
  }

  handleChangeTypeQuery = e => {
    const { value: typeQuery } = e.target

    const { onChange, isNested } = this.props

    if (!isNested) return

    onChange({
      target: {
        typeQuery,
      },
    })
  }

  handleRemoveBlock = () => {
    const { isNested, onChange } = this.props

    if (!isNested) return

    onChange({
      target: {
        isDeleted: true,
      },
    })
  }

  handleRemoveCondition = index => () => {
    const { value: query, onChange } = this.props

    const newQuery = [...query]
    newQuery.splice(index, 1)

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  handleAddCondition = () => {
    const { value: query, onChange } = this.props

    const newQuery = [...query, {}]

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  handleAddBlock = () => {
    const { value: query, onChange } = this.props

    const newQuery = [...query, { type: 'block', typeQuery: 'and', query: [] }]

    onChange({
      target: {
        value: newQuery,
        name: this.props.name,
      },
    })
  }

  renderValueInput = (item, options, index, disabled) => {
    const { classes } = this.props
    const { value = [] } = item
    const { type, values } = options

    let props = {
      value,
      disabled,
      onChange: this.handleValueChange(index),
    }

    switch (type) {
      case 'date':
        return <CustomMenuField {...props} style={{ minHeight: 34 }} options={values} />
      case 'text':
        return <MultiInput {...props} />
      case 'enum':
      case 'multiple':
        return (
          <CustomMultipleMenu {...props} classes={{ select: classes.select }} options={values} />
        )
      case 'boolean':
        return <TrueFalseSwitch {...props} />
      case 'integer':
      case 'float':
      case 'array':
        return (
          <NumberField
            {...props}
            inputProps={{
              step: type === 'integer' ? 1.0 : undefined,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              style: { minHeight: 34 },
            }}
            type={'number'}
            value={value || 0}
            fullWidth
          />
        )
      // no default
    }
  }

  render() {
    let {
      classes,
      className,
      value = [{}],
      label,
      typeQuery = 'AND',
      disabled: queryDisabled,
      isList,
      isNested,
    } = this.props

    if (!value) value = [{}]
    if (!Array.isArray(value)) value = [value || {}]

    let disabledRemoving = value.length === 1

    const { options, groupedOptions } = this.state

    const optionsLoaded = !!options

    return (
      <div
        className={classNames(classes.root, className, {
          [classes.nestedRoot]: isNested,
        })}
      >
        {isNested && (
          <Grid container item xs={12} direction="row" justify="space-between">
            <Grid item xs={4}>
              <CustomMenuField
                value={typeQuery}
                label={'Type of query'}
                options={[
                  {
                    value: 'and',
                    label: 'AND',
                  },
                  {
                    value: 'or',
                    label: 'OR',
                  },
                ]}
                onChange={this.handleChangeTypeQuery}
              />
            </Grid>
            <IconButton onClick={this.handleRemoveBlock}>
              <Icon>delete</Icon>
            </IconButton>
          </Grid>
        )}

        <InputLabel style={{ position: 'inherit' }} shrink>
          {label}
        </InputLabel>
        {insertBetween(
          value.map((item, i) => {
            let { type, field, operator, disabled: itemDisabled = false } = item

            if (i === 0 && isList) itemDisabled = true

            let disabled = queryDisabled || itemDisabled || !optionsLoaded

            if (type === 'block') {
              return (
                <Grid
                  spacing={16}
                  alignItems={'center'}
                  className={classNames(classes.condition, classes.nestedCondition)}
                  key={`condition_${i}`}
                >
                  <SegmentationQuery
                    key={`block_${i}`}
                    {...this.props}
                    {...item}
                    label={null}
                    value={item.query}
                    onChange={this.handleNestedValueChange(i)}
                    isNested
                  />
                </Grid>
              )
            }

            const fieldOptions = options && options.find(o => o.field === field)

            return (
              <Grid
                container
                spacing={16}
                alignItems={'center'}
                className={classes.condition}
                key={`condition_${i}`}
              >
                <Grid item xs={4}>
                  <DropDown
                    onChange={this.handleFieldChange(i)}
                    disabled={disabled}
                    value={field}
                    options={groupedOptions}
                  />
                </Grid>

                <Grid item xs={2}>
                  {(!optionsLoaded || (fieldOptions && fieldOptions.operators)) && (
                    <DropDown
                      onChange={this.handleOperatorChange(i)}
                      disabled={disabled}
                      value={operator}
                      options={fieldOptions && fieldOptions.operators}
                    />
                  )}
                </Grid>

                <Grid container item xs={6} direction="row">
                  <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    {optionsLoaded &&
                      fieldOptions &&
                      this.renderValueInput(item, fieldOptions, i, disabled)}
                  </div>

                  <IconButton
                    onClick={this.handleRemoveCondition(i)}
                    disabled={disabled || disabledRemoving}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            )
          }),
          i => (
            <Typography key={`and_${i}`} className={classes.and}>
              <b>{typeQuery.toUpperCase()}</b>
            </Typography>
          ),
        )}

        {!queryDisabled && (
          <>
            <Button
              className={classes.addButton}
              variant={'outlined'}
              onClick={this.handleAddCondition}
            >
              Add Condition
            </Button>
            {!isNested && (
              <Button
                className={classes.addButton}
                variant={'outlined'}
                style={{ marginLeft: '5px' }}
                onClick={this.handleAddBlock}
              >
                Add Block
              </Button>
            )}
          </>
        )}
      </div>
    )
  }
}

export default withApi(withStyles(styles)(SegmentationQuery))
