import React, { useCallback, useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const FONT_SIZE = '13px'

const styles = () => ({
  menuLabel: {
    paddingLeft: 0,
    '&:first-child': {
      paddingLeft: '72px',
      fontSize: FONT_SIZE,
    },
  },
  menuItem: {
    padding: '5px 0',
  },
  menuText: {
    fontSize: FONT_SIZE,
  },
  negated: {
    '& .enhanced-table-head-select-item-label': { textDecorationLine: 'line-through' },
  },
  negateButton: {
    opacity: 0,
    borderRadius: 12,
    maxHeight: 24,
    minHeight: 24,
    padding: 2,
    marginRight: 4,
  },
  negateButtonHovered: {
    opacity: 1,
  },
})

function EnhancedTableHeadSelectItem({
  classes,
  label,
  selected,
  negated,
  onClick,
  onNegateClick,
  excludeNegated
}) {
  const negateClickHandler = useCallback(
    e => {
      e.stopPropagation()
      onNegateClick(e)
    },
    [onNegateClick],
  )

  const [hovered, setHovered] = useState(false)

  const onMouseEnter = () => {
    setHovered(true)
  }
  const onMouseLeave = () => {
    setHovered(false)
  }

  return (
    <MenuItem
      className={classes.menuItem}
      onClick={onClick}
      selected={selected}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Checkbox checked={selected} color={'primary'} />
      <ListItemText
        primary={label}
        className={classNames(classes.menuLabel, { [classes.negated]: selected && negated })}
        primaryTypographyProps={{ className: classes.menuText }}
      />

      {excludeNegated ? null :
        <Button
        color={'secondary'}
        className={classNames(classes.negateButton, hovered && classes.negateButtonHovered)}
        onClick={negateClickHandler}
      >
        {negated ? 'Include' : 'Exclude'}
      </Button>}
    </MenuItem>
  )
}

export default withStyles(styles)(EnhancedTableHeadSelectItem)

const MenuProps = {
  PaperProps: {
    style: {
      fontSize: FONT_SIZE,
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export { MenuProps }
