import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import Button from '@material-ui/core/Button/Button'
import Colors from '~shared/assets/styles/colors'

const styles = () => ({
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 2,
    width: '100%',
  },
  jpointsWrapper: {
    display: 'flex',
    height: '100%',
    padding: '0 24px',
    alignItems: 'center',
  },
  jpoints: {
    height: '100%',
    position: 'absolute',
    backgroundColor: Colors.Brand,
    color: Colors.White,
    top: 0,
    right: 0,
  },
  arrowLeft: {
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: `10px solid ${Colors.Brand}`,
    position: 'absolute',
    left: -10,
  },
})

export default withStyles(styles)(
  ({ children, classes, pending, disabled, jpoints, id, ...rest }) => (
    <Button id={id} disabled={disabled || pending} {...rest}>
      {children}
      {pending && <LinearProgress className={classes.progress} />}
    </Button>
  ),
)
