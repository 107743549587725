import React, { useEffect, useMemo, useCallback } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails'
import Icon from '@material-ui/core/Icon/Icon'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CustomSwitchField from '~components/atom/FieldFactory/CustomSwitchField'
import classNames from 'classnames'
import IconAttention from '~src/icons/attention.svg'

const checkBoxIconStyle = {
  background: '#FFFFFF',
  border: '1px solid #000000',
  borderRadius: '4px',
  fontSize: '15px',
  width: '16px',
  height: '16px',
  alignItems: 'center',
  justifyContent: 'center',
}

const label = {
  display: 'block',
  fontFamily: 'Cambay',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '22px',
  textTransform: 'uppercase',
  verticalAlign: 'middle',
  marginTop: '4px',
  marginLeft: '4px',
}

const operationsName = {
  list: 'Просмотр таблицы',
  create: 'Создание',
  delete: 'Удаление',
  openDetail: 'Открыть запись',
  patch: 'Сохранить изменения',
  createBasedOn: 'Создание на основе',
  saveFromDraft: 'Сохранение черновика',
  edit: 'Создание черновика',
  preview: 'Предпросмотр',
  deleteCmsDraft: 'Удаление черновика',
  requestApproval: 'Запросить подтверждение',
  rejectCmsDraft: 'Отклонение черновика',
  publish: 'Опубликовать',
  deleteBulk: 'Удаление выбранных',
  saveFilters: 'Сохранить фильтры',
  deleteSingle: 'Удаление записи',
  downloadCsv: 'Экспорт CSV',
  uploadCsv: 'Импорт CSV',
  invite: 'Пригласить',
  duplicate: 'Копирование',
  createFromFile: 'Создать из файла',
  download: 'Скачивание',
  uploadFiles: 'Загрузка файлов',
  approveUpload: 'Подтвердить загрузку',
  rejectUpload: 'Отклонить загрузку',
  approveUploadSingle: 'Подтвердить одиночную загрузку',
  rejectUploadSingle: 'Отклонить одиночную загрузку',
  ssometadata: 'Скачивание методанных SSO',
  ssotester: 'Тестирование SSO',
  ssotestcertificate: 'Тестирование сертификата',
  statsSysCampaigns: 'Просмотр статистики',
  executeSysCampaigns: 'Запуск компаний',
  testSysCampaigns: 'Тестирование компаний',
  testSysWebhook: 'Тестирование вебхуков',
  hook: 'Вызвать хук',
  impersonate: 'Выполнять имперсонацию',
}

const styles = ({ spacing: { unit }, breakpoints }) => ({
  root: {
    width: '100%',
    marginBottom: '0px',
    boxShadow: 'none',
    borderTop: '1px solid #EBEBEB',
    '&:first-child': {
      borderTop: 'none',
    },
    '&:last-child': {
      borderBottom: '1px solid #EBEBEB',
    },
  },
  summaryContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '20px',
  },
  panelSummaryRoot: {
    padding: 0,
  },
  detailContainer: {
    display: 'flex',
    margin: '0px 25px',
    // borderBottom: '1px solid #EBEBEB',
  },
  detailText: {
    display: 'block',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'Roboto',
    color: '#000000',
    textTransform: 'uppercase',
    lineHeight: '16px',
    letterSpacing: '.055em',
    marginLeft: '66px',
    marginBottom: '18px',
  },
  label: {
    ...label,
  },
  halfOpacity: {
    opacity: '0.5 !important',
  },
  subSectionLabel: {
    ...label,
    marginTop: '1px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '16px',
    textTransform: 'none',

    display: 'flex',
    alignItems: 'center',
  },
  subSectionCounter: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#9A9A9A',
    marginLeft: '10px',
  },
  checkBoxIcon: {
    ...checkBoxIconStyle,
  },
  checkBoxCheckedIcon: {
    ...checkBoxIconStyle,
    color: '#FFFFFF',
    background: '#000000',
  },
  checkBoxChecked: {
    color: '#000000 !important',
  },
  summaryContentChecked: {
    background: '#F8F8F8',
    marginBottom: '0px',
    marginTop: '0px',
  },
  pannelInner: {
    padding: '0 24px 0 22px',
    marginBottom: '0px',
    marginTop: '0px',
    '&:first-child': {
      borderTop: 'none',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  pannelSummaryInner: {
    padding: '0 24px 0 6px',
  },
  panelInnerDetails: {
    padding: '0 24px 6px',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    marginLeft: '18px',
    marginTop: '-16px',
  },
  operation: {
    marginRight: '26px',
    fontSize: '12px !important',
  },
  operationLabel: {
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
  },
  attentionText: {
    width: '100%',
    position: 'relative',
    marginLeft: '66px',
    display: 'block',
    color: '#E03C31',
    fontSize: '14px',
    paddingLeft: '25px',
    marginBottom: '24px',
    '&:before': {
      content: '""',
      position: 'absolute',
      minWidth: 16,
      minHeight: 16,
      top: '2px',
      left: '0px',
      background: `url(${IconAttention}) no-repeat`,
      [breakpoints.down('xs')]: {
        display: 'none',
        marginRight: 0,
      },
    },
  },
})

const RoleAccordion = props => {
  const { classes, label, categories = [], data = {}, defaultAction, setData, removeData } = props

  const [categoriesChecked, setCategoriesChecked] = React.useState({})
  const [sectionChecked, setSectionChecked] = React.useState(false)
  const [expandedStruc, setExpandedStruc] = React.useState({})

  const isDangerZone = useMemo(() => categories.some(category => category.isDangerZone), [
    categories,
  ])

  const categoryByColName = useMemo(() => {
    const result = {}
    categories.forEach(category => {
      result[category.colName] = category
    })
    return result
  }, [categories])

  const getAmountActions = useCallback(
    colName => {
      if (!categories) return 0

      const category = categoryByColName[colName]
      let amountActions = category?.actions?.length || 0
      if (defaultAction) amountActions++
      return amountActions
    },
    [categoryByColName],
  )

  const isSomeActionIsActive = categories.some(
    category =>
      Object.keys(data).includes(category.colName) && data[category.colName]?.actions?.length > 0,
  )

  const isAllCategoriesIsActive = categories.every(category => {
    let actionsLength = category?.actions?.length || 0

    if (defaultAction || category.defaultAction) actionsLength++

    return (
      Object.keys(data).includes(category.colName) &&
      data[category.colName]?.actions?.length === actionsLength
    )
  })

  useEffect(() => {
    let countUsedCategories = 0
    const categoriesChecked = {}

    for (const category of categories) {
      let amountActions = category.actions?.length

      if (defaultAction || category.defaultAction) amountActions++

      const categoryActionsCount = data[category.colName] && data[category.colName]?.actions?.length

      if (categoryActionsCount === amountActions) {
        countUsedCategories++
        categoriesChecked[category.colName] = true
      }
    }

    setCategoriesChecked(categoriesChecked)

    if (countUsedCategories === categories.length) {
      setSectionChecked(true)
    }
  }, [categories])

  useEffect(() => {
    const categActive = Object.values(categoriesChecked).some(val => val)
    setSectionChecked(categActive)
  }, [categoriesChecked, setSectionChecked])

  const handleActionCheckboxClick = (event, category, action, defaultValue) => {
    event.stopPropagation()

    const { colName, defaultAction: defaultActionCategory } = category

    const defaultActionLocal = defaultActionCategory || defaultAction

    const checked =
      defaultValue !== undefined && defaultValue !== null ? defaultValue : event.target.checked

    const isCustomAction = action?.customAction
    let amountActiveActions = data[colName]?.actions?.length || 0
    if (checked) {
      if (!amountActiveActions && defaultActionLocal) {
        setData(colName, defaultActionLocal.name)
        amountActiveActions++
      }
      setData(colName, isCustomAction ? action : action.name)
      amountActiveActions++
    } else {
      removeData(colName, isCustomAction ? action : action.name)
      amountActiveActions--
    }

    const amountActions = getAmountActions(colName)

    if (amountActions === amountActiveActions) {
      setCategoriesChecked(prevState => ({
        ...prevState,
        [colName]: true,
      }))
    } else if (
      !amountActiveActions ||
      (categoriesChecked[colName] && amountActiveActions < amountActions)
    ) {
      setCategoriesChecked(prevState => ({
        ...prevState,
        [colName]: false,
      }))
    }
  }

  const getPanelStyle = colName =>
    classNames(classes.pannelInner, classes.root, {
      [classes.summaryContentChecked]: data[colName]?.actions?.length,
    })

  const handleSubSectionClick = (event, category, defaultValue) => {
    event.stopPropagation()

    const { colName, actions, defaultAction: defaultActionCategory } = category

    if (!getExpanded(colName) && defaultValue === undefined) {
      changeExpanded(colName)
    }

    const defaultActionLocal = defaultActionCategory || defaultAction

    const haveDefaultValue = defaultValue !== undefined && defaultValue !== null

    setCategoriesChecked(prevState => ({
      ...prevState,
      [colName]: haveDefaultValue ? defaultValue : !prevState[colName],
    }))

    for (let action in actions) {
      handleActionCheckboxClick(event, category, actions[action])
    }

    // if there is a defaultAction and defaultValue is false, then we need to disable everything except defaultAction
    if (defaultActionLocal) {
      handleActionCheckboxClick(event, category, defaultActionLocal)
    }
  }

  const handeSectionClick = (event, sections) => {
    event.stopPropagation()
    if (isSomeActionIsActive && !isAllCategoriesIsActive) event.target.checked = true
    setSectionChecked(event.target.checked)

    for (let section in sections) {
      handleSubSectionClick(event, sections[section], event.target.checked)
    }
  }

  const getExpanded = useCallback(
    name => {
      if (expandedStruc[name] !== undefined) return expandedStruc[name]
      return false
    },
    [expandedStruc],
  )

  const changeExpanded = useCallback(
    name => {
      setExpandedStruc(prevState => ({
        ...prevState,
        [name]: !prevState[name],
      }))
    },
    [setExpandedStruc],
  )

  return (
    <ExpansionPanel
      classes={{ root: classes.root }}
      expanded={getExpanded(label)}
      onChange={() => changeExpanded(label)}
    >
      <ExpansionPanelSummary
        classes={{
          content: classes.summaryContent,
          root: classes.panelSummaryRoot,
        }}
        expandIcon={<Icon>expand_more</Icon>}
      >
        <>
          <Checkbox
            classes={{
              root: classNames({
                [classes.halfOpacity]: !sectionChecked && !isSomeActionIsActive,
              }),
              checked: classes.checkBoxChecked,
              indeterminateIcon: classes.indertminateIcon,
            }}
            checkedIcon={<Icon classes={{ root: classes.checkBoxCheckedIcon }}>check</Icon>}
            indeterminateIcon={<Icon classes={{ root: classes.checkBoxCheckedIcon }}>remove</Icon>}
            icon={<Icon classes={{ root: classes.checkBoxIcon }} />}
            indeterminate={isSomeActionIsActive && !isAllCategoriesIsActive}
            onClick={event => handeSectionClick(event, categories)}
            checked={sectionChecked || isAllCategoriesIsActive}
          />
          <span
            className={classNames(classes.label, {
              [classes.halfOpacity]: !sectionChecked && !isSomeActionIsActive,
            })}
          >
            {label}
          </span>
        </>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
        <Grid container className={classes.detailContainer}>
          {isDangerZone && (
            <Typography className={classes.attentionText}>
              Внимание! Изменения в данных разделах могут повлиять на работоспособность сайта
            </Typography>
          )}
          <Typography className={classes.detailText}>
            Выберите разделы, доступные пользователю:
          </Typography>
          <Grid container>
            {categories.map((category, index) => {
              const amountActions = getAmountActions(category.colName)
              const amountActiveActions = data[category.colName]?.actions?.length

              const isIndeterminate =
                amountActions !== amountActiveActions && amountActiveActions > 0

              const defaultActionLocal = category.defaultAction || defaultAction

              const actions = [...category.actions]

              if (defaultActionLocal) {
                actions.unshift(defaultActionLocal)
              }

              return (
                <ExpansionPanel
                  key={category.colName + index}
                  classes={{ root: getPanelStyle(category.colName) }}
                  expanded={getExpanded(category.colName)}
                  onChange={() => changeExpanded(category.colName)}
                >
                  <ExpansionPanelSummary
                    classes={{
                      content: classNames(classes.pannelSummaryInner, classes.summaryContent),
                    }}
                  >
                    <Checkbox
                      classes={{
                        root: classNames({
                          [classes.halfOpacity]:
                            !categoriesChecked[category.colName] && !isIndeterminate,
                        }),
                        checked: classes.checkBoxChecked,
                      }}
                      checked={(categoriesChecked[category.colName] && !isIndeterminate) || false}
                      checkedIcon={
                        <Icon classes={{ root: classes.checkBoxCheckedIcon }}>check</Icon>
                      }
                      indeterminateIcon={
                        <Icon classes={{ root: classes.checkBoxCheckedIcon }}>remove</Icon>
                      }
                      indeterminate={!categoriesChecked[category.colName] && isIndeterminate}
                      icon={<Icon classes={{ root: classes.checkBoxIcon }} />}
                      onClick={event => handleSubSectionClick(event, category)}
                    />
                    <span
                      className={classNames(classes.subSectionLabel, {
                        [classes.halfOpacity]:
                          !categoriesChecked[category.colName] && !isIndeterminate,
                      })}
                    >
                      {category.label}
                      {amountActiveActions > 0 && (
                        <span className={classes.subSectionCounter}>
                          {amountActiveActions} из {amountActions}
                        </span>
                      )}
                    </span>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails classes={{ root: classes.panelInnerDetails }}>
                    <Grid container className={classes.operationContainer}>
                      {actions.map((action, index) => {
                        const actionExists =
                          data[category.colName]?.actions?.some(obj => obj.name === action.name) ||
                          data[category.colName]?.actions?.includes(action.name)
                        const value =
                          data && Array.isArray(data[category.colName]?.actions) && actionExists

                        const isDefaultAction = action.name === defaultActionLocal.name
                        const isDisabled = isDefaultAction && value && amountActiveActions > 1

                        const id = `${category.colName}-${action.name}-${index}`

                        return (
                          <CustomSwitchField
                            id={id}
                            key={id}
                            value={value}
                            label={operationsName[action.name] || action.name}
                            isRoleSwitch={true}
                            rootClass={classes.operation}
                            labelClass={classes.operationLabel}
                            disabled={isDisabled}
                            onClick={event => handleActionCheckboxClick(event, category, action)}
                          />
                        )
                      })}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            })}
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default withStyles(styles)(RoleAccordion)
