import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'

const styles = theme => ({
  root: {},
})

const RoleSwitch = withStyles(theme => ({
  bar: {
    backgroundColor: '#E0E0E0',
    width: 32,
    height: 16,
    opacity: 1,
    marginTop: '-8px',
  },
  checked: {
    '& + $bar': {
      backgroundColor: '#00B2A9 !important',
      opacity: 1,
    },
  },
  icon: {
    padding: 0,
    backgroundColor: '#FFFFFF',
    width: 12,
    height: 12,
  },
}))(Switch)

class CustomSwitchField extends Component {
  handleChange = e => {
    const { onChange, name } = this.props
    onChange && onChange({ target: { name, value: e.target.checked } })
  }

  render() {
    const {
      classes,
      helperText,
      disabled,
      value,
      name,
      label,
      helperTextClass,
      rootClass,
      labelClass,
      isRoleSwitch,
      onClick,
    } = this.props

    const SwitchComponent = isRoleSwitch ? RoleSwitch : Switch
    return (
      <FormControl className={classnames(classes.root, rootClass)}>
        <FormControlLabel
          classes={{ label: !isRoleSwitch ? classes.label : labelClass }}
          disabled={disabled}
          control={
            <SwitchComponent
              checked={value}
              value={Boolean(value).toString()}
              onChange={this.handleChange}
              onClick={onClick}
              name={name}
              disabled={disabled}
            />
          }
          label={label}
        />
        {helperText && (
          <FormHelperText className={helperTextClass} disabled={disabled}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

export default withStyles(styles)(CustomSwitchField)
