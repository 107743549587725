import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { htmlTextAnchorParser } from '~shared/utils'

class Toggle extends React.Component {
  state = {}

  static getDerivedStateFromProps(props, state) {
    if (state.checked !== undefined) {
      return {}
    }
    return { checked: props.value }
  }

  render() {
    const { className, label, captions, id } = this.props
    const { checked } = this.state

    return (
      <Grid container className={className} direction={'row'}>
        <Grid xs={4} item container alignItems={'center'}>
          <Typography>{htmlTextAnchorParser(label)}</Typography>
        </Grid>
        <Grid xs={8} item container alignItems={'center'}>
          <FormControlLabel
            control={
              <Switch
                id={id}
                color="secondary"
                checked={this.state.checked}
                onChange={() => this.setState({ checked: !checked })}
              />
            }
            label={captions[checked ? 1 : 0]}
          />
        </Grid>
      </Grid>
    )
  }

  checkValidity = () => !this.props.required || this.state.checked

  getValue = () => this.state.checked
}

// this is necessary, otherwise 'innerRef'ing this Component in FormElementsGroup will not work
export default withStyles({})(Toggle)
