import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import classNames from 'classnames'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = () => ({
  // for overriding purposes
  root: {},
  select: {},
  menuItem: {},
})

const DropDown = withStyles(styles)(
  class DropDownComponent extends React.Component {
    constructor(props) {
      super(props)
      const { displayEmpty = true, enumData = [] } = props

      const value =
        props.value !== undefined
          ? props.value
          : !displayEmpty && !!enumData.length && enumData[0] !== undefined
          ? enumData[0].value !== undefined
            ? enumData[0].value
            : enumData[0]
          : undefined
      this.state = { value }
    }

    _renderSelect = () => {
      const { classes, enumData = [], displayEmpty = true, id } = this.props
      const { value } = this.state

      return (
        <Select
          id={`${id}-select`}
          displayEmpty={displayEmpty}
          value={value}
          onChange={this._handleChange}
          input={<Input name="age" id={`${id}-dropdown`} />}
          className={classes.select}
          // renderValue={() => {
          //   return selectedOption ? selectedOption.value : value
          // }}
        >
          {Array.isArray(enumData) &&
            enumData.map((option, index) => {
              let { value, label } = option
              if (typeof option === 'string' || typeof option === 'number') value = label = option

              return (
                <MenuItem key={index} value={value} className={classes.menuItem}>
                  {label}
                </MenuItem>
              )
            })}
        </Select>
      )
    }

    render() {
      const { classes, className, label, required, invalid, errorText, id, disabled } = this.props
      // let selectedOption = enumData.find(option => option && option.value && option.value === value)
      const { value } = this.state

      return label ? (
        <FormControl
          className={classNames(classes.root, className)}
          required={required}
          error={invalid && !disabled}
        >
          <InputLabel htmlFor={`${id}-dropdown`} shrink={value !== undefined} required={required}>
            {label}
          </InputLabel>
          {this._renderSelect()}
          <FormHelperText id={`${id}-error-text`}>
            {errorText && invalid ? errorText : ''}
          </FormHelperText>
        </FormControl>
      ) : (
        this._renderSelect()
      )
    }

    checkValidity = () =>
      !this.props.required || (this.state.value !== undefined && this.state.value !== '')

    getValue = () => this.state.value

    _handleChange = ({ target: { value } = {} }) => {
      this.setState({ value })
      const { onChange } = this.props
      onChange && onChange(value)
    }
  },
)

export default DropDown
