import React from 'react'
import classNames from 'classnames'
import { FixedSizeGrid } from 'react-window'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { TabContainer } from './TabContainer'
import { styles } from './Styles'
import MenuFilter from './MenuFilter'
import MediaItem from './MediaItem'

class ListMediaContainer extends React.PureComponent {
  constructor() {
    super()
    this.windowRef = React.createRef()
    this.state = { gridWidth: 0, gridHeight: 0, columnCount: 0 }
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // calculate the space to render the grid for the images
  handleResize = () => {
    if (this.windowRef.current) {
      const container = this.windowRef.current.getBoundingClientRect()
      const gridWidth = container.width
      const gridHeight = window.innerHeight
      const columnCount = Math.floor(gridWidth / 144)
      this.setState({ gridWidth, gridHeight, columnCount })
    }
  }

  render() {
    const {
      classes,
      searchMedias,
      contentType,
      upstreamFiles,
      deleteFile,
      viewFullImage,
      types,
      preSelectedTypes,
      tags,
      useThisMedia,
      isList,
      canDelete,
    } = this.props

    const { gridWidth, gridHeight, columnCount } = this.state

    return (
      <TabContainer>
        {isList && (
          <MenuFilter
            classes={classes}
            types={types}
            tags={tags}
            handleOnChange={searchMedias}
            preSelectedTypes={preSelectedTypes}
          />
        )}
        <div className={classNames(classes.wrapper, isList && classes.wrapperIsList)}>
          <Grid container spacing={24} alignItems={'center'} direction={'row'} justify={'center'}>
            <div style={{ height: '100%', width: '100%' }} ref={this.windowRef}>
              {upstreamFiles.length ? (
                <FixedSizeGrid
                  columnCount={columnCount}
                  columnWidth={144}
                  width={gridWidth}
                  rowCount={Math.ceil(upstreamFiles.length / columnCount)}
                  rowHeight={146}
                  height={gridHeight}
                >
                  {position => {
                    const k = position.rowIndex * columnCount + position.columnIndex
                    const file = upstreamFiles[k]
                    if (file) {
                      return (
                        <MediaItem
                          key={k}
                          k={k}
                          file={file}
                          position={position}
                          deleteFile={deleteFile}
                          viewFullImage={viewFullImage}
                          useThisMedia={useThisMedia}
                          isList={isList}
                          contentType={contentType}
                          canDelete={canDelete}
                        />
                      )
                    }
                    return null
                  }}
                </FixedSizeGrid>
              ) : (
                <Typography variant="h6">No medias yet, try to upload some.</Typography>
              )}
            </div>
          </Grid>
        </div>
        {!isList && (
          <MenuFilter
            classes={classes}
            types={types}
            tags={tags}
            handleOnChange={searchMedias}
            preSelectedTypes={preSelectedTypes}
          />
        )}
      </TabContainer>
    )
  }
}

export default withStyles(styles)(ListMediaContainer)
