import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import withStyles from '@material-ui/core/styles/withStyles'
import FieldFactory from './FieldFactory'
import classNames from 'classnames'

const styles = theme => ({
  root: {},
  tableWrapper: {
    overflowX: 'scroll',
  },
  delete: {
    width: '1px',
  },
  addButton: {
    marginTop: 5,
  },
})

class CustomTable extends Component {
  getFakeEvent = (rowData, columnData = this.props.value.columnData) => {
    let { name } = this.props

    return {
      target: {
        name,
        type: 'table',
        value: {
          rowData,
          columnData,
        },
      },
    }
  }

  updateRow = (row, col, rowIndex, e) => {
    let {
      value: { rowData: prevRowData },
    } = this.props

    const rowData = [...prevRowData]
    row = { ...row }

    row[col.name] = e.target.value

    rowData.splice(rowIndex, 1, row)

    return rowData
  }

  deleteRow = rowIndex => {
    let {
      value: { rowData },
    } = this.props
    rowData = [...rowData]

    rowData.splice(rowIndex, 1)

    return rowData
  }

  addRow = row => {
    let {
      value: { rowData = [] },
    } = this.props
    rowData = [...rowData]

    rowData.push(row)

    return rowData
  }

  handleDeleteRow = (row, rowIndex) => e => {
    let { onChange } = this.props
    const fakeEvent = this.getFakeEvent(this.deleteRow(rowIndex))

    onChange(fakeEvent)
  }

  handleAddRow = () => {
    const {
      value: { columnData },
    } = this.props
    let { onChange } = this.props

    const row = {}

    for (let col of columnData) {
      row[col.name] = ''
    }

    const fakeEvent = this.getFakeEvent(this.addRow(row))

    onChange(fakeEvent)
  }

  handleChange = (col, rowIndex) => e => {
    let {
      onChange,
      value: { rowData: rowDataProps },
    } = this.props
    let row = rowDataProps[rowIndex]

    const rowData = this.updateRow(row, col, rowIndex, e)

    const fakeEvent = this.getFakeEvent(rowData)
    onChange(fakeEvent)
  }

  render() {
    let { classes, className, disabled, value, history, settings, label } = this.props

    if (!value) {
      return null
    }
    const { disableCreate = false, disableDelete = false } = settings || {}

    const { columnData, rowData } = value

    return (
      <div className={classNames(classes.root, className)}>
        <InputLabel style={{ position: 'inherit' }} shrink>
          {label}
        </InputLabel>

        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableCell className={classes.delete} padding="none" />
                {columnData &&
                  columnData.map(col => {
                    const { name: colName, label: colLabel } = col
                    return (
                      <TableCell
                        key={colName}
                        numeric={col.numeric}
                        padding="none"
                        className={classes.head}
                      >
                        {colLabel || colName}
                      </TableCell>
                    )
                  }, this)}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData &&
                rowData.map(({ _link, ...row }, rowIndex) => {
                  return (
                    <TableRow
                      onClick={_link && history && (() => history.push(_link))}
                      key={rowIndex}
                    >
                      <TableCell padding="none" className={classes.cell}>
                        {!disabled && !disableDelete && (
                          <IconButton onClick={this.handleDeleteRow(row, rowIndex)}>
                            <Icon>delete</Icon>
                          </IconButton>
                        )}
                      </TableCell>

                      {columnData.map(col => {
                        return (
                          <CustomTableField
                            item={{
                              ...col,
                              disabled: col.disabled || row[col.name] === null || disabled,
                              value: row[col.name],
                            }}
                            key={`${rowIndex}_${col.name}`}
                            classes={classes}
                            onChange={this.handleChange(col, rowIndex)}
                          />
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>

        {!disabled && !disableCreate && (
          <Button className={classes.addButton} variant="outlined" onClick={this.handleAddRow}>
            Add Row
          </Button>
        )}
      </div>
    )
  }
}

const customStyles = ({ spacing: { unit } }) => ({
  root: {
    height: unit * 4,
    maxHeight: unit * 4,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  rootBoolean: {
    alignItems: 'flex-start',
  },
  underline: {
    padding: `0 ${unit * 0.5}px`,
    boxSizing: 'border-box',
    bottom: '-1px',
    '&:before': {
      borderBottomColor: 'transparent',
    },
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
  },
})

class CustomTableField extends React.Component {
  constructor(props) {
    super(props)

    const {
      classes,
      item: { type, name },
    } = props

    this.padding = 'none'

    this.extraProps = {
      label: '',
      name: '',
      originalName: name,
      onChange: this.handleChange,
      fullWidth: true,
      helperText: '',
      isInlined: true,
      classes: {
        root: classes.root,
      },
      input: <Input classes={{ underline: classes.underline }} />,
      InputProps: {
        classes: {
          root: classes.root,
          underline: classes.underline,
        },
      },
    }

    switch (type) {
      case 'enum':
        this.extraProps.classes.select = classes.select
        break
      case 'reference':
      case 'boolean':
        this.extraProps.classes.root = classNames(classes.root, classes.rootBoolean)
        this.padding = 'dense'
        break
      default:
        break
    }
  }

  handleChange = e => {
    const { onChange } = this.props

    onChange &&
      onChange({
        target: e.target,
        ref: this,
      })
  }

  render() {
    const { item, onChange, classes, ...rest } = this.props

    return (
      <TableCell {...rest} padding={this.padding}>
        {FieldFactory.getFieldByType({
          ...item,
          ...this.extraProps,
        })}
      </TableCell>
    )
  }
}

CustomTableField = withStyles(customStyles)(CustomTableField)

export default withStyles(styles)(CustomTable)

export { CustomTableField }
