import React, { Component } from 'react'
import CustomMedia from '~components/atom/FieldFactory/CustomMedia/CustomMedia'
import ReactQuill, { Quill } from 'react-quill'
import withStyles from '@material-ui/core/styles/withStyles'

import DocBlot from './DocBlot'
import ImageBlot from './ImageBlot'
import PriceBlot from './PriceBlot'
import ReferenceBlot from './ReferenceBlot'
import VideoBlot from './VideoBlot'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'

import Popup from '~shared/components/organisms/Popup'
import PopupBox from '~shared/components/popup/PopupBox'
import PopupHeader from '~shared/components/popup/PopupHeader'
import PopupFooter from '~shared/components/popup/PopupFooter'
import PopupContent from '~shared/components/popup/PopupContent'

import 'react-quill/dist/quill.snow.css'

const icons = Quill.import('ui/icons')

icons['doc'] = '<i class="fa fa-file" aria-hidden="true"></i>'
icons['link'] = '<i class="fa fa-forward" aria-hidden="true"></i>'
icons['reference'] = '<i class="fa fa-link" aria-hidden="true"></i>'
icons['price'] = '<i class="material-icons">format_shapes</i>'

const styles = ({ spacing: { unit } }) => ({
  root: {},
  rootGroup: {
    flexDirection: 'row',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  refPopUpHeader: {
    '& > div': {
      padding: 0,
    },
  },
  refPopUpHeaderText: {
    padding: unit * 2,
  },
})

const openModalHandler = async function (type) {
  const { disabled, api } = this.props

  if (!disabled) {
    this.setCursorPositionAndText()

    const { request } = api.getReferenceSuggestions('~sitemap')
    const response = await request
    const sitemaps = response.result

    this.setState({
      openModal: true,
      type: type,
      sitemaps,
    })
  }
}

const openModalReference = async function () {
  const { disabled, api } = this.props

  if (!disabled) {
    const { request } = api.getReferenceSuggestions('~sitemap')

    const response = await request
    const sitemaps = response.result

    this.setCursorPositionAndText()

    this.setState({
      openModalReference: true,
      sitemaps: sitemaps,
    })
  }
}

Quill.register(ImageBlot)
Quill.register(VideoBlot)
Quill.register(DocBlot)
Quill.register(ReferenceBlot)
Quill.register(PriceBlot)

class CustomRichText extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
      openModalReference: false,
      type: null,
      checkBoxValue: 'a',
      checkBoxTargetValue: '_self',
      sitemapSearch: '',
      selectedText: '',
      selectedCursorPosition: null,
    }

    this.toolbarMenu = {}

    // Quill instance
    this.quillRef = null
    this.reactQuillRef = null

    const { settings: { toolbar } = {} } = this.props

    this.generateToolBar(toolbar)
    // this.handleSitemapSearchChange = debounce(this.handleSitemapSearchChange, 300)
  }

  componentDidMount() {
    this.attachQuillRefs()
    this.embedToolTips()
  }

  componentDidUpdate() {
    this.attachQuillRefs()
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return
    this.quillRef = this.reactQuillRef.getEditor()
  }

  embedToolTips = () => {
    const { settings: { toolbar } = {} } = this.props

    const toolbarItems = this.quillRef.container.parentElement.querySelectorAll(
      '.ql-formats > *:not(select)',
    )

    toolbarItems.forEach((element, idx) => {
      element.addEventListener('mouseenter', () => {
        const item = toolbar[idx]
        if (!item || !item.tooltip) return

        this.setState({
          tooltip: {
            title: `${item.tooltip}`,
            element,
            open: true,
          },
        })
      })
      element.addEventListener('mouseleave', () => {
        this.setState({
          tooltip: { ...this.state.tooltip, open: false },
        })
      })
    })
  }

  generateToolBar(toolbar) {
    const modulesItems = []
    const formatsItems = []
    let item
    let obj

    for (obj of toolbar) {
      switch (obj.type) {
        case 'select':
          item = {
            [obj.name]: obj.options,
          }
          break
        case 'button':
          if (obj.value) {
            item = {
              [obj.name]: obj.value,
            }
          } else {
            item = obj.name
          }
          break
        default:
          break
      }

      formatsItems.push(obj.name)
      modulesItems.push(item)
    }

    this.toolbarMenu = {
      modules: {
        clipboard: {
          matchVisual: false,
        },
        toolbar: {
          container: modulesItems,
          handlers: {
            image: openModalHandler.bind(this, 'image'),
            video: openModalHandler.bind(this, 'video'),
            doc: openModalHandler.bind(this, 'application'),
            reference: openModalReference.bind(this),
            price: this.insertBlock.bind(this, 'price'),
          },
        },
      },
      formats: formatsItems,
    }
  }

  insertBlock(type) {
    const cursorPosition = (this.quillRef.getSelection && this.quillRef.getSelection()) || 0
    const text = this.quillRef.getText(cursorPosition)

    this.quillRef.deleteText(cursorPosition)

    this.quillRef.insertEmbed(cursorPosition.index, type, { text: text })

    this.quillRef.setSelection(cursorPosition.index ? cursorPosition.index + 1 : 0)
  }

  handleChange = (content, delta, user, editor) => {
    const { disabled } = this.props
    if (disabled) return

    const { onUpdate } = this.props

    onUpdate && onUpdate(editor.getHTML())
  }

  handleBlur = (previousRange, source, editor) => {
    const { disabled } = this.props
    if (disabled) return

    const { onChange } = this.props

    onChange &&
      onChange({
        target: {
          name: this.props.name,
          value: editor.getHTML(),
        },
      })
  }

  useThisMedia = (file, event) => {
    // event.stopPropagation()
    let type
    let remove = false
    const { selectedText, selectedCursorPosition } = this.state

    if (!file) return false

    if (/image/.test(file.type)) {
      type = 'image'
    } else if (/video/.test(file.type)) {
      type = 'video'
    } else if (/application/.test(file.type)) {
      type = 'doc'
      remove = true
    } else if (/reference/.test(file.type)) {
      remove = true
      type = 'reference'
    }

    if (type === 'image' && !this.state.pendingFile) {
      this.setState({
        pendingFile: file,
        openModal: false,
        openModalReference: true,
      })

      return true
    }

    if (type === 'reference' && this.state.pendingFile) {
      this.quillRef.insertEmbed(
        selectedCursorPosition.index,
        type,
        Object.assign(file, {
          pendingFile: this.state.pendingFile,
          src: file.path,
          alt: file.name,
          data_object_id: file._id,
          text: selectedText,
        }),
      )

      this.setState({
        pendingFile: undefined,
        openModal: false,
        openModalReference: false,
      })

      return false
    }

    if (remove) {
      this.quillRef.deleteText(selectedCursorPosition)
    }

    this.quillRef.insertEmbed(
      selectedCursorPosition.index,
      type,
      Object.assign(file, {
        src: file.path,
        alt: file.name,
        data_object_id: file._id,
        text: selectedText,
      }),
    )

    this.quillRef.setSelection(selectedCursorPosition.index ? selectedCursorPosition.index + 1 : 0)

    this.clearCursorPositionAndText()
    this.setState({
      openModal: false,
      openModalReference: false,
      sitemapSearch: '',
      pendingFile: undefined,
    })

    return false
  }

  onCloseModal = () => {
    this.clearCursorPositionAndText()
    this.useThisMedia(this.state.pendingFile)
    this.setState({
      openModalReference: false,
      sitemapSearch: '',
    })
  }

  handleCloseParent = () => {
    this.clearCursorPositionAndText()
    this.setState({
      openModal: false,
    })
  }

  handleListItemClick = (sitemap, event) => {
    event.stopPropagation()
    sitemap.type = 'reference'
    sitemap.style = this.state.checkBoxValue
    sitemap.sitemapid = sitemap._id
    sitemap.target = this.state.checkBoxTargetValue

    this.useThisMedia(sitemap, event)
  }

  handleStyleChange = event => {
    this.setState({ checkBoxValue: event.target.value })
  }

  handleTargetChange = event => {
    this.setState({ checkBoxTargetValue: event.target.value })
  }

  handleSitemapSearchChange = event => {
    this.setState({ sitemapSearch: event.target.value })
  }

  setCursorPositionAndText = () => {
    const cursorPosition = (this.quillRef.getSelection && this.quillRef.getSelection()) || 0
    const text = this.quillRef.getText(cursorPosition)
    this.setState({
      selectedText: text,
      selectedCursorPosition: cursorPosition,
    })
  }

  clearCursorPositionAndText = () => {
    this.setState({
      selectedText: '',
      selectedCursorPosition: null,
    })
  }

  render() {
    const { root, label, helperText, disabled, value, name, classes, ...rest } = this.props
    const { settings: { backgroundColor } = {} } = rest
    const {
      openModalReference,
      openModal,
      sitemaps,
      checkBoxValue,
      checkBoxTargetValue,
      sitemapSearch,
      tooltip = {},
    } = this.state

    const filteredSitemap =
      sitemaps &&
      sitemaps.filter(sitemap => {
        if (sitemapSearch === '') {
          return true
        }
        return sitemap._name.toLowerCase().includes(sitemapSearch)
      })

    return (
      <React.Fragment>
        <FormLabel disabled={disabled} style={{ fontSize: '0.825rem' }}>
          {label}
        </FormLabel>
        <ReactQuill
          className={disabled && classes.disabled}
          style={{ backgroundColor }}
          modules={this.toolbarMenu.modules}
          formats={this.toolbarMenu.formats}
          value={value ? value.normalize('NFC') : ''}
          name={name}
          readOnly={disabled}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          ref={el => {
            if (el) {
              this.reactQuillRef = el
              return this.reactQuillRef
            }
          }}
        />

        <Popper open={tooltip.open} anchorEl={tooltip.element} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ padding: '0.5rem 1rem' }}>
                <Typography className={classes.typography}>{tooltip.title}</Typography>
              </Paper>
            </Fade>
          )}
        </Popper>

        {openModalReference && (
          <Popup open={openModalReference} onClose={this.onCloseModal}>
            <PopupBox>
              <PopupHeader onClose={this.onCloseModal} className={classes.refPopUpHeader}>
                <Typography variant="h4" className={classes.refPopUpHeaderText}>
                  Sitemap
                </Typography>
                <TextField
                  autoFocus
                  fullWidth
                  value={sitemapSearch}
                  onChange={this.handleSitemapSearchChange}
                  placeholder={'Type to filter ...'}
                  InputProps={{
                    style: { padding: `7px 15px`, boxSizing: 'border-box' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </PopupHeader>

              <PopupContent>
                <Paper square>
                  <List>
                    {Array.isArray(filteredSitemap) &&
                      filteredSitemap.map(sitemap => {
                        return (
                          <ListItem
                            key={sitemap._id}
                            button
                            onClick={event => this.handleListItemClick(sitemap, event)}
                          >
                            <ListItemText primary={sitemap._name} />
                          </ListItem>
                        )
                      })}
                  </List>
                </Paper>
              </PopupContent>

              <PopupFooter>
                <Grid container>
                  <Grid item xs={6}>
                    <FormLabel component="legend">Style</FormLabel>
                    <RadioGroup
                      aria-label="Select a style"
                      name="style"
                      value={checkBoxValue}
                      onChange={this.handleStyleChange}
                      classes={{
                        root: classes.rootGroup,
                      }}
                    >
                      <FormControlLabel value="a" control={<Radio />} label="Link" />
                      <FormControlLabel value="button" control={<Radio />} label="Button" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormLabel component="legend">Target</FormLabel>
                    <RadioGroup
                      aria-label="Select a target"
                      name="target"
                      value={checkBoxTargetValue}
                      onChange={this.handleTargetChange}
                      classes={{
                        root: classes.rootGroup,
                      }}
                    >
                      <FormControlLabel value="_self" control={<Radio />} label="Self" />
                      <FormControlLabel value="popup" control={<Radio />} label="Popup" />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </PopupFooter>
            </PopupBox>
          </Popup>
        )}

        {openModal && (
          <CustomMedia
            isRichText
            useThisMedia={this.useThisMedia.bind(this)}
            typeMedia={this.state.type}
            {...rest}
            handleCloseParent={this.handleCloseParent.bind(this)}
          />
        )}
        {helperText && <FormHelperText disabled={disabled}>{helperText}</FormHelperText>}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CustomRichText)
