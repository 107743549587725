import Colors from '~shared/assets/styles/colors'

const iconAndColorByStatus = {
  submitted: { icon: 'hourglass_empty', color: Colors.DarkGrey }, // 'hourglass_empty' 'more_horiz'
  processing: { icon: 'find_in_page', color: Colors.DarkGrey }, // 'search' 'pageview' 'supervisor_account'
  dispatched: { icon: 'local_shipping', color: Colors.DarkGrey },
  failed: { icon: 'block', color: Colors.Red },
  succeeded: { icon: 'check_circle_outline', color: Colors.Brand },
  pending: { icon: 'hourglass_empty', color: Colors.DarkGrey },
  partiallydelivered: { icon: 'hourglass_empty', color: Colors.DarkGrey },
  delivered: { icon: 'check_circle_outline', color: Colors.Brand },
  collected: { icon: 'check_circle_outline', color: Colors.Brand },
  cancelled: { icon: 'block', color: Colors.Red },
  rejected: { icon: 'block', color: Colors.Red },
  inactive: { icon: 'block', color: Colors.Red },
  approved: { icon: 'check_circle_outline', color: Colors.Brand },
  active: { icon: 'check_circle_outline', color: Colors.Brand },
  deleted: { icon: 'delete', color: Colors.Red },

  invoiced: { icon: 'check_circle_outline', color: Colors.Brand },

  //TODO: icon for "generic" statuses ?
  generic1: { icon: 'check_circle_outline', color: Colors.Brand },
  generic2: { icon: 'check_circle_outline', color: Colors.Brand },

  draft: { icon: 'create', color: Colors.DarkGrey },
  activated: { icon: 'send', color: Colors.PaleTeal },
  completed: { icon: 'check_circle', color: Colors.GrassGreen },

  email: { icon: 'email', color: Colors.DarkGrey },
  sms: { icon: 'sms', color: Colors.DarkGrey },
  push: { icon: 'tab', color: Colors.DarkGrey },
  webhook: { icon: 'code', color: Colors.DarkGrey },
  notificationcenter: { icon: 'notifications', color: Colors.DarkGrey },
  newcontent: { icon: 'insert_drive_file', color: Colors.DarkGrey },
  reminders: { icon: 'calendar_today', color: Colors.DarkGrey },
  updatesandchanges: { icon: 'update', color: Colors.DarkGrey },

  exportdata: { icon: 'cloud_upload' },
  changehook: { icon: 'rss_feed' },
  importdata: { icon: 'cloud_download' },
  syncfilessftp: { icon: 'file_copy' },

  article: { icon: 'notes' },
  quiz: { icon: 'question_answer' },
  upload: { icon: 'backup' },
}

const getIconAndColorByStatus = status => {
  return iconAndColorByStatus[status] || { icon: 'flash_on', color: Colors.CoolGrey }
}

const constantFilters = {
  CLEAR_FILTERS: 'clear filters',
}

export { getIconAndColorByStatus, constantFilters }
