import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon/Icon'
import ListSubheader from '@material-ui/core/ListSubheader'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Colors from '~shared/assets/styles/colors'
import classNames from 'classnames'

const styles = ({ spacing: { unit } }) => ({
  root: {},
  selectEmpty: {
    opacity: 0.5,
  },
  colorOption: {
    width: '100%',
    height: '100%',
    minHeight: unit + 3,
  },
  searchInputContainer: {
    fontWeight: 'unset',
    marginTop: -unit,
    paddingTop: unit,
    lineHeight: 'normal',
    backgroundColor: Colors.White,
  },
  infoLabel: {
    opacity: 0.7,
    width: '100%',
    margin: `${unit * 2}px ${unit * 2}px ${unit}px`,
  },
  noMatches: {
    textAlign: 'center',
  },
})

const MAX_OPTIONS_RENDERED = 50

class CustomMenuField extends Component {
  state = { open: false, searchInput: '' }

  handleInputChange = e => {
    this.setState({ searchInput: e.target.value })
  }
  handleOpen = () => {
    this.setState({ open: true, searchInput: '' })
  }
  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    let { inputProps, InputProps, classes, helperText, value, label, options, ...rest } = this.props
    let { searchInput, open } = this.state

    const selectedOption = options.find(o => o.value === value)
    if (!selectedOption) value = undefined

    let searchEnabled = options.length >= 20

    let numSliced = 0
    searchInput = searchInput.trim().toLowerCase()
    const isSearching = searchEnabled && searchInput
    if (isSearching) {
      options = options.filter(
        option =>
          (option.value && option.value.toLowerCase().includes(searchInput)) ||
          (option.label && option.label.toLowerCase().includes(searchInput)) ||
          (option.tags &&
            option.tags.filter(tag => tag.toLowerCase().includes(searchInput)).length > 0),
      )
    }

    if (options.length > MAX_OPTIONS_RENDERED) {
      numSliced = options.length - MAX_OPTIONS_RENDERED
      options = options.slice(0, MAX_OPTIONS_RENDERED)
    }

    return (
      <FormControl fullWidth {...rest} classes={{ root: classes.root }}>
        {label && <InputLabel shrink={true}>{label}</InputLabel>}

        <Select
          open={open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          classes={{
            root: classes.root,
            select:
              value === undefined
                ? classNames(classes.select, classes.selectEmpty)
                : classes.select,
          }}
          {...rest}
          value={value || ''}
          displayEmpty
          renderValue={() => (selectedOption ? this._renderOption(selectedOption) : null)}
          MenuProps={{
            disableAutoFocusItem: searchEnabled,
          }}
        >
          {searchEnabled && (
            <ListSubheader className={classes.searchInputContainer}>
              <TextField
                autoFocus
                fullWidth
                onChange={this.handleInputChange}
                placeholder={'Type to filter ...'}
                autoComplete={'off'}
                onClick={e => e.stopPropagation()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </ListSubheader>
          )}

          {options.map(option => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              {this._renderOption(option)}
            </MenuItem>
          ))}

          {numSliced > 0 && (
            <Typography className={classes.infoLabel} variant={'subheading'}>
              {numSliced} more available, please narrow your search.
            </Typography>
          )}

          {isSearching && options.length === 0 && (
            <Typography
              className={classNames(classes.infoLabel, classes.noMatches)}
              variant={'subheading'}
            >
              No matches found
            </Typography>
          )}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

  _renderOption = option => {
    const { settings: { variant } = {}, classes } = this.props
    const { label } = option

    if (variant === 'icon') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon style={{ fontSize: '20px' }}>{label}</Icon>
          <span style={{ marginLeft: 8 }}>({label})</span>
        </div>
      )
    }
    if (variant === 'colorSpectrum') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: label === 'white' ? '1px solid rgba(0,0,0,0.2)' : undefined,
            background: Colors[label],
          }}
          className={classes.colorOption}
        />
      )
    }

    return label
  }
}

CustomMenuField.propTypes = {
  helperText: PropTypes.string,
}

export default withStyles(styles)(CustomMenuField)
