import React from 'react'
import classNames from 'classnames'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import debounce from 'lodash/debounce'
import { htmlTextAnchorParser } from '~shared/utils'
import Colors from '~shared/assets/styles/colors'
import SignatureCanvas from 'react-signature-canvas'
import Button from '@material-ui/core/Button/Button'
import IntlUtil from '~shared/utils/IntlUtil'
import ConnexusLabels from '~shared/constants/ConnexusLabels'

class Signature extends React.Component {
  state = {
    touched: false,
    canvasRect: { width: 0, height: 0 },
  }

  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.canvasRef = React.createRef()
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // calculate the space to render the grid for the images
  handleResize = debounce(() => {
    const { canvasRect } = this.state
    if (this.wrapperRef.current) {
      const newRect = this.wrapperRef.current.getBoundingClientRect()
      if (newRect.width !== canvasRect.width) this.setState({ canvasRect: newRect })
    }
  }, 500)

  getValue() {
    return this.canvasRef.current.toDataURL('image/png', 0.75)
  }

  checkValidity() {
    return this.state.touched === true
  }

  handleEnd = () => {
    this.setState({ touched: true })
  }

  clear = () => {
    this.canvasRef.current.clear()
    this.setState({ touched: false })
  }

  render() {
    const { classes, className, id, text, errorText, invalid, required } = this.props
    const { canvasRect } = this.state

    const label = text && htmlTextAnchorParser(text)

    return (
      <FormControl className={classNames(classes.root, className)}>
        {label && <Typography className={required ? classes.required : ''}>{label}</Typography>}

        <div className={classes.canvasWrapper} ref={this.wrapperRef}>
          <SignatureCanvas
            onEnd={this.handleEnd}
            ref={this.canvasRef}
            canvasProps={{
              className: classes.canvas,
              width: canvasRect.width,
              height: canvasRect.height,
            }}
          />
        </div>

        <Button className={classNames(classes.button)} onClick={this.clear}>
          {IntlUtil.label(ConnexusLabels.CLEAR)}
        </Button>
        <FormHelperText id={`${id}-error-text`} className={classNames(classes.error)}>
          {errorText && invalid ? errorText : ''}
        </FormHelperText>
      </FormControl>
    )
  }
}

const styles = ({ spacing: { unit } }) => ({
  root: {},
  label: {},
  canvasWrapper: {
    width: '100%',
    paddingTop: '66%',
    position: 'relative',
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#f0f0f0',
    borderBottom: '1px solid #000',
  },
  error: {
    color: Colors.Red,
  },
  required: {
    '&:after': {
      content: '" *"',
    },
  },
  button: {
    marginTop: unit / 2,
    alignSelf: 'flex-start',
  },
})

export default withStyles(styles)(Signature)
