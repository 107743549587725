import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ImageSelection from './ImageSelection'
import { styles } from './Styles'

class CustomUserMedia extends React.Component {
  render() {
    const { classes, value, api, settings: { height: customHeight } = {} } = this.props
    value.path = `${api.connector.baseUrl}/@/userChallengeMedia/${value.fileName}`
    value.type = value.mimetype
    return (
      <ImageSelection
        showControls={false}
        classes={classes}
        value={value}
        handleOpen={undefined}
        disabled={true}
        handleDeleteImageFromItem={undefined}
        clickedOnVideo={undefined}
        isPlaying={false}
        customUserMedia={true}
        customHeight={customHeight}
      />
    )
  }
}

export default withStyles(styles)(CustomUserMedia)
