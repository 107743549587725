import React from 'react'
import { withSnackbar } from 'notistack'
import { CustomSnackBarContent } from '~components/atom/FieldFactory/CustomSnackBar'
import { withApi } from '~shared/api/ApiContext'

class SnackMessages extends React.Component {
  componentDidMount() {
    const { api: { connector } = {} } = this.props
    connector && (this.errorSubscription = connector.listenToAlerts(this.onAlert))
  }

  componentWillUnmount() {
    this.errorSubscription && this.errorSubscription.unsubscribe()
  }

  onAlert = alert => {
    const { enqueueSnackbar } = this.props
    const { message, stack, variant, dontHide = false } = alert

    if (message === undefined) {
      return
    }
    // This function call is the one that is in charge of creating the snacks
    enqueueSnackbar('', {
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 6000,
      persist: dontHide,
      children: key => (
        <CustomSnackBarContent
          onClose={() => this.handleClose2(key)}
          message={message + (stack ? ': ' + stack : '')}
          variant={variant}
        />
      ),
    })
  }

  handleClose2 = key => {
    const { closeSnackbar } = this.props
    closeSnackbar(key)
  }

  render() {
    return <div></div>
  }
}

export default withSnackbar(withApi(SnackMessages))
