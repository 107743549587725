import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Colors from '~shared/assets/styles/colors'
import { fade } from '@material-ui/core/styles/colorManipulator'

const styles = theme => ({
  root: {},
  select: {
    minHeight: 34,
  },
  paddingSelect: {
    paddingTop: 0,
  },
  clusterName: {
    fontWeight: 'bold',
    paddingLeft: 16,
    backgroundColor: fade(Colors.CoolGrey, 0.2),
    textTransform: 'uppercase',
  },
})

class DropDown extends React.Component {
  render() {
    const { classes, value, options, label = 'Please select', onChange, ...rest } = this.props

    return (
      <FormControl fullWidth>
        <Select
          className={classes.select}
          value={value || ''}
          displayEmpty
          onChange={onChange}
          MenuProps={{ MenuListProps: { classes: { padding: classes.paddingSelect } } }}
          {...rest}
        >
          {!value && (
            <MenuItem value={''} disabled>
              {label}
            </MenuItem>
          )}
          {options &&
            options.map(option => {
              if (!option) return null

              let value
              let label
              let disabled = false
              let isClusterName = false
              if (typeof option === 'string') {
                label = option
                value = option
              } else {
                ;({ value, label, disabled, isClusterName } = option)
              }

              return (
                <MenuItem
                  key={value}
                  value={value}
                  className={isClusterName ? classes.clusterName : null}
                  disabled={disabled}
                >
                  {label}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(styles)(DropDown)
