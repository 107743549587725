import React, { useEffect, useState, memo } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { withWebSocket } from '~src/WebSocketContext'

const styles = ({ spacing: { unit } }) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    width: 15,
    height: 15,
    marginRight: '10px !important',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: 'none',
  },
  hoverText: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: 40,
    backgroundColor: 'white',
    border: '1px solid gray',
    height: 50,
    padding: '0px 8px',
    borderRadius: '4px',
    pointerEvents: 'none',
    fontFamily: 'Roboto',
    fontSize: 16,
  },
})

const ProgressStatusCircle = memo(({ classes, status, socket, id }) => {
  const [isHovered, setIsHover] = useState(false)

  const [statusFromSocket, setStatus] = useState('')

  const channel = `updateJobStatus:${id}`

  useEffect(() => {
    if (socket) {
      const listener = info => {
        const { status } = info
        if (status) {
          setStatus(status)
        }
      }
      if (!socket.hasListeners(channel)) {
        socket.on(channel, listener)
      }

      return () => {
        socket.off(channel, listener)
      }
    }
  }, [])

  const getStatusColor = () => {
    const actualStatus = statusFromSocket || status

    switch (actualStatus) {
      case 'pending':
        return '#FFD700'
      case 'failed':
        return '#FF0000'
      case 'success':
        return '#32CD32'
      default:
        return '#32CD32'
    }
  }

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <div className={classes.container}>
      {isHovered && (
        <div className={classes.hoverText}>
          <Typography>{`Last execute: ${statusFromSocket || status}`}</Typography>
        </div>
      )}
      <Paper
        className={classes.circle}
        style={{ backgroundColor: getStatusColor() }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  )
})

export default withWebSocket(withStyles(styles)(ProgressStatusCircle))
