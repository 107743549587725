import React, { useCallback, useMemo } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Icon,
  IconButton,
} from '@material-ui/core'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import classNames from 'classnames'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {
    '&:not(:hover) $buttons': {
      display: 'none',
    },
  },
  title: {
    fontSize: '1.08rem',
    whiteSpace: 'pre',
  },
  expansionPanel: {
    '&:first-child': {
      borderRadius: 0,
    },
    '&:last-child': {
      borderRadius: 0,
    },
    backgroundColor: palette.grey[100],
  },
  dragHandle: {
    marginRight: unit * 0.5,
    marginLeft: -unit,
    '&:hover': {
      opacity: 0.5,
    },
  },
  buttons: {
    marginTop: -unit,
    marginBottom: -unit,
    marginLeft: 'auto',
    '&>button': {
      marginLeft: -unit * 0.5,
      marginRight: -unit * 0.5,
    },
  },
})

const DragHandle = SortableHandle(({ className }) => (
  <Icon
    onMouseDown={e => {
      e.preventDefault()
      e.stopPropagation()
    }}
    className={className}
  >
    drag_handle
  </Icon>
))

// TODO: probably should be moved somewhere else or ideally received as a prop
const itemElementsByType = {
  text: ['elementType', 'sectionTitle', 'titleStyle', 'content'],
  table: ['elementType', 'sectionTitle', 'titleStyle', 'table'],
  image: ['elementType', 'sectionTitle', 'titleStyle', 'imageCaption', 'externalUrl', 'image'],
}

const ElementsListItem = withStyles(styles)(
  SortableElement(
    ({
      className,
      classes,
      expanded,
      id,
      value: { elementType, sectionTitle } = {},
      sortable,
      onClick,
      onDelete,
      onCopy,
      onCut,
      renderFields,
    }) => {
      const elements = useMemo(() => itemElementsByType[elementType] || [], [])
      const renderedTitle = sectionTitle ? (
        [
          <b>
            {elementType}
            {'   •   '}
          </b>,
          sectionTitle,
        ]
      ) : (
        <b>{elementType}</b>
      )
      const createHandleButtonClick = useCallback(
        method => e => {
          e.stopPropagation()
          method && method(id, e)
        },
        [id],
      )
      const renderedFields = useMemo(() => renderFields(elements, id), [renderFields, elements])
      const renderedButtons = useMemo(
        () => (
          <div className={classes.buttons}>
            {onCut && (
              <IconButton size={'small'} onClick={createHandleButtonClick(onCut)}>
                <Icon>cut</Icon>
              </IconButton>
            )}
            {onCopy && (
              <IconButton size={'small'} onClick={createHandleButtonClick(onCopy)}>
                <Icon>copy</Icon>
              </IconButton>
            )}
            {onDelete && (
              <IconButton size={'small'} onClick={createHandleButtonClick(onDelete)}>
                <Icon>delete</Icon>
              </IconButton>
            )}
          </div>
        ),
        [onDelete, onCopy, onCut, createHandleButtonClick, classes],
      )

      return (
        <li className={classNames(className, classes.root)}>
          <ExpansionPanel
            classes={{ root: classes.expansionPanel }}
            square
            elevation={0}
            expanded={expanded}
          >
            <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>} onClick={onClick}>
              {sortable && <DragHandle className={classes.dragHandle} />}
              <Typography classes={{ root: classes.title }} align="left" color={'primary'}>
                {renderedTitle}
              </Typography>
              {renderedButtons}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={24}>
                {renderedFields}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </li>
      )
    },
  ),
)

export default ElementsListItem
