import React from 'react'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import Route from 'react-router-dom/Route'
import AppFrame from './AppFrame'
import PopupWrapper from './shared/components/popup/PopupWrapper'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import PortalTheme, { THEME } from './shared/assets/styles/theme'
import { ApiProvider } from './shared/api/ApiContext'
import ApiConnector from './shared/api/ApiConnector'
import AdminApi from './api/AdminApi'
import SnackMessages from './components/molecules/SnackMessages'
import ErrorHandler from './shared/components/pages/ErrorHandler'
import { createPageWrapper } from './shared/components/pages'
import { ProvideLocalClipboard } from './hooks/useLocalClipboard'
import WebSocketContext from './WebSocketContext'

import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName'
import jssPreset from '@material-ui/core/styles/jssPreset'
import { create } from 'jss'
import rtl from 'jss-rtl'
import JssProvider from 'react-jss/lib/JssProvider'
import { SnackbarProvider } from 'notistack'
import ModelDetails from './components/page/ModelViewWrapper'

import ModelList from './components/page/ModelViewWrapper'
import AdminLoginPage from './components/page/AdminLoginPage'
import AdminLoginResetPage from './shared/components/pages/ResetPasswordPage'
import AdminLoginResetPasswordPage from './components/page/AdminLoginSetPasswordPage'
import AdminDashboard from './components/page/AdminDashboard'
import AdminVersionControlPage from './components/page/AdminVersionControlPage'
import GenericMessagePage from './shared/components/templates/GenericMessagePage'
import AdminVersionDiffPage from './components/page/AdminVersionDiffPage'
import AdminIssuesPage from './components/page/AdminIssuesPage'
import io from 'socket.io-client'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const generateClassName = createGenerateClassName()

const apiConnector = new ApiConnector(process.env.REACT_APP_API_URL)
const adminApi = new AdminApi(apiConnector)

const componentByName = {
  ModelDetails,
  ModelList,
  AdminLoginPage,
  AdminLoginResetPage,
  AdminLoginResetPasswordPage,
  AdminDashboard,
  AdminVersionControlPage,
  AdminVersionDiffPage,
  GenericMessagePage,
  AdminIssuesPage,
}

const getComponentByName = name => {
  return componentByName[name]
}

// Yo dawg, I heard you liked wrappers, so I wrapped your wrapper in another wrapper
const PopupWrapperWrapper = props => (
  <PopupWrapper minWidth={'90vw'} maxWidth={960} maxHeight={'90vh'} {...props} />
)

let PageWrapper = createPageWrapper({
  getComponentByName,
  AppFrame,
  PopupWrapper: PopupWrapperWrapper,
  locale: 'en',
})

class App extends React.PureComponent {
  state = {}

  constructor(props) {
    super(props)

    this.state = {
      theme: createMuiTheme(THEME),
      socket: this.joinSocketChannel(),
    }
  }

  joinSocketChannel = () => {
    const socketChannel = 'adminData'
    if (this.hasSocketJoined) return
    this.hasSocketJoined = true

    const res = /^(https?:\/\/)?(.*?)\/(.+)$/gi.exec(process.env.REACT_APP_API_URL)
    const socket = io(res[2] || '/', { path: `/${res[3]}/socket`, transports: ['websocket'] })

    socket.once('connect', () => {
      socket.emit('join', socketChannel)
    })

    socket.on('disconnect', () => {
      this.hasSocketJoined = false
    })

    return socket
  }

  handleRouteChange = ({ pageData: { appFrame } = {} }) => {
    const { settings: { technical: { captchaId, captchaVisibility } = {} } = {} } = appFrame || {}

    apiConnector.setupCaptcha(captchaId, captchaVisibility)
  }

  handleThemeChange = newTheme => {
    this.setState({ theme: createMuiTheme({ ...THEME, ...newTheme }) })
  }

  render() {
    const { theme, socket } = this.state

    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <PortalTheme.Provider value={this.handleThemeChange}>
          <MuiThemeProvider theme={theme}>
            <WebSocketContext.Provider value={{ socket }}>
              <ProvideLocalClipboard>
                <BrowserRouter>
                  <ErrorHandler>
                    <ApiProvider api={adminApi}>
                      <SnackbarProvider maxSnack={4} dense>
                        <SnackMessages />
                        <Route
                          render={props => (
                            <PageWrapper {...props} onRouteChange={this.handleRouteChange} />
                          )}
                        />
                      </SnackbarProvider>
                    </ApiProvider>
                  </ErrorHandler>
                </BrowserRouter>
              </ProvideLocalClipboard>
            </WebSocketContext.Provider>
          </MuiThemeProvider>
        </PortalTheme.Provider>
      </JssProvider>
    )
  }
}

export default App
