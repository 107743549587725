import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import CustomJSON from '~components/atom/FieldFactory/CustomJSON'
import { withWebSocket } from '~src/WebSocketContext'
import _ from 'lodash'
import moment from 'moment'

const styles = () => ({
  root: {},
  select: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 34,
  },
  condition: {
    width: '100%',
    margin: 0,
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.1)',
  },
  and: {
    margin: '4px 0',
  },
  addButton: {
    marginTop: 5,
  },
})

const JobProgressJson = ({ classes, className, socket, document }) => {
  const [state, setState] = useState({
    Info: 'If the job supports logging, it will display execution messages here when it is running',
  })

  const documentId = document._id

  const channel = `sendJobMessage:${documentId}`

  useEffect(() => {
    if (socket) {
      const listener = info => {
        const { message } = info
        if (message) {
          setState(prevState => {
            const currentDate = moment()
            const shortFormat = currentDate.format('YYYY-MM-DD HH:mm:ss')
            const updatedState = {
              ...prevState,
              [shortFormat]: message,
            }

            const keys = _.keys(updatedState)

            if (keys.length > 10) {
              delete updatedState[keys[0]]
            }

            return updatedState
          })
        }
      }
      if (!socket.hasListeners(channel)) {
        socket.on(channel, listener)
      }

      return () => {
        socket.off(channel, listener)
      }
    }
  }, [])

  return (
    <div className={classNames(classes.root, className)}>
      <CustomJSON helperText={'Last 10 executing messages'} value={state} />
    </div>
  )
}

export default withWebSocket(withStyles(styles)(JobProgressJson))
