import { create } from 'zustand'

export const useLabelsStore = create(set => ({
  labels: {},
  setLabels: labels => set({ labels }),
  addLabels: labels => set(state => ({ ...state, labels: { ...state.labels, ...(labels || {}) } })),
}))

export const setLabelsToStore = labels => useLabelsStore.setState({ labels })
export const addLabelsToStore = labels =>
  useLabelsStore.setState(state => ({ ...state, labels: { ...state.labels, ...(labels || {}) } }))
