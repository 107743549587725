import React from 'react'
import Typography from '@material-ui/core/Typography/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import IntlUtil from '~shared/utils/IntlUtil'
import Colors from '~shared/assets/styles/colors'
import classNames from 'classnames'

const styles = ({ palette, spacing: { unit } }) => ({
  root: {
    padding: `${unit * 1.17}px ${unit * 2}px`,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  label: {
    marginLeft: unit * 0.5,
    marginRight: unit * 0.5,
  },
  text: {
    color: Colors.White,
  },
})

class PointsBox extends React.Component {
  render() {
    const { classes, className, points = 0 } = this.props

    return (
      <div
        className={classNames(classes.root, className)}
        style={{ backgroundColor: Colors.Brand }}
      >
        <Typography variant="h5" className={classNames(classes.text)}>
          {IntlUtil.num(points)}
        </Typography>
        <Typography className={classNames(classes.text, classes.label)}>
          {IntlUtil.label('J_POINTS')}
        </Typography>
      </div>
    )
  }
}

export default withStyles(styles)(PointsBox)
