import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import { withApi } from '~shared/api/ApiContext'
import RoleAccordion from '~components/molecules/Roles/RoleAccordion/RoleAccordion'

const styles = () => ({
  root: {},
  select: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 34,
  },
  condition: {
    width: '100%',
    margin: 0,
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.1)',
  },
  and: {
    margin: `4px 0`,
  },
  addButton: {
    marginTop: 5,
  },
})

class AdminRole extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {},
    }
  }

  async componentDidMount() {
    const {
      document,
      addition: { models = [] },
    } = this.props

    this.setState({ sections: models, data: document.permissions || {} })
  }

  setData = (key, value) => {
    this.setState(prevState => {
      const newState = { ...prevState }
      if (newState.data[key]) {
        if (!newState.data[key].actions) newState.data[key].actions = []
        if (typeof value === 'string' && !newState.data[key].actions.includes(value)) {
          newState.data[key].actions.push(value)
        } else {
          const existingObject = newState.data[key].actions.find(obj => obj.name === value.name)
          if (!existingObject) {
            newState.data[key].actions.push({
              name: value.name,
              alias: value.alias,
              confirm: value.confirm,
              label: value.label,
              icon: value.icon,
            })
          }
        }
      } else {
        newState.data[key] = {
          actions: [value],
        }
      }
      this._updateData(newState.data)
      return newState
    })
  }

  removeData = (key, value) => {
    const filterFunc =
      typeof value === 'string' ? item => item !== value : item => item.name !== value.name
    this.setState(prevState => {
      const newState = { ...prevState }
      if (newState.data[key]) {
        const updatedArray = newState.data[key].actions.filter(filterFunc)
        newState.data[key].actions = updatedArray
      }
      this._updateData(newState.data)
      return newState
    })
  }

  _updateData = data => {
    const { onUpdate } = this.props

    onUpdate({
      ...data,
    })
  }

  render() {
    let {
      classes,
      className,
      addition: { defaultAction = {} },
    } = this.props

    const { sections = [], data } = this.state

    return (
      <div className={classNames(classes.root, className)}>
        <Grid item>
          {sections.map((section, index) => (
            <RoleAccordion
              label={section.label}
              key={`${section.label}-${index}`}
              categories={section.items}
              defaultAction={defaultAction}
              data={data}
              setData={this.setData}
              removeData={this.removeData}
            />
          ))}
        </Grid>
      </div>
    )
  }
}

export default withApi(withStyles(styles)(AdminRole))
