import React from 'react'
import classNames from 'classnames'
import { Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Colors from '~shared/assets/styles/colors'

const TypeChip = ({ label, type = 'none', classes, className }) => (
  <Chip
    label={label || type}
    classes={{ root: classNames(classes.root, classes[type.toLowerCase()]) }}
    className={className}
  />
)

const styles = ({ spacing: { unit } }) => ({
  none: {},
  root: {
    '&:first-child': {
      marginLeft: -unit + 1,
    },
  },
  added: {
    backgroundColor: Colors.CorporateGreen,
  },
  updated: {
    backgroundColor: Colors.Orange,
  },
  removed: {
    backgroundColor: Colors.PaleRed,
  },
  export: {
    backgroundColor: Colors.LightBlue,
  },
  crm: {
    backgroundColor: Colors.GrassGreen,
  },
})

export default withStyles(styles)(TypeChip)
