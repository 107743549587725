import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Colors from '~shared/assets/styles/colors'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import withWidth from '@material-ui/core/withWidth'
import Logo from './Logo'
import CommonHeaderItems from './CommonHeaderItems'

const styles = ({ palette, spacing: { unit }, breakpoints }) => ({
  root: {
    height: 64,
    paddingLeft: 0,
    backgroundColor: Colors.White,
    color: Colors.Black,
    display: 'flex',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    position: 'fixed',
    top: 0,
    zIndex: 2,
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    '& > *+*': {
      marginLeft: unit,
    },
  },
  versionChip: {
    backgroundColor: fade(Colors.Black, 0.1),
    color: Colors.Black,
    // marginLeft: unit,
    '&:hover': {
      color: palette.primary.main,
    },
  },
})

class Header extends React.Component {
  state = {
    scrolled: false,
    showLogoutDialog: false,
  }

  render() {
    const {
      classes,
      member,
      handleDrawerToggle,
      data: { items = [] },
      platformName,
      version,
      width,
    } = this.props

    return (
      <Toolbar className={classes.root}>
        <div style={{ display: 'flex', alignItems: 'space-between' }}>
          {width === 'sm' || width === 'xs' ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <Icon>menu</Icon>
            </IconButton>
          ) : null}
          <Logo platformName={platformName.toUpperCase()} />
        </div>

        <div className={classes.items}>
          {width !== 'xs' ? <CommonHeaderItems items={items} member={member} /> : null}
          {version && (
            <Chip
              label={`v${version}`}
              className={classes.versionChip}
              onClick={() => {}}
              clickable
              component={Link}
              to={'/version'}
            />
          )}
        </div>
      </Toolbar>
    )
  }
}

export default withWidth()(withStyles(styles)(Header))
